import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http_service.service';
import { environment } from '../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../services/notifications/noti_service.service';

@Component({
  selector: 'app-utilerias',
  templateUrl: './utilerias.component.html',
  styleUrls: ['./utilerias.component.scss']
})
export class UtileriasComponent implements OnInit {
  private listUsers = [];
  private listAccesorios = [];
  private listComponentes = [];
  private listProductos = [];
  private listVidrios = [];

  bproductos = false;
  bvidrios = false;
  bperfiles = false;
  baccesorios = false;
  busuarios = false;
  badmin = false;

  constructor(private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit() {
    this.getUserByToken();
    
  }

  showListUsers() {
    this.httpService.showUsers().subscribe(
      data => {

        if (data.data.hasOwnProperty('users')) {
          this.listUsers = data.data.users;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListUsers utilerias');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    )
  }

  showListAccesorios() {
    this.httpService.showAccesorios().subscribe(
      data=> {
        if (data.data.hasOwnProperty('accesorios')) {
          this.listAccesorios = data.data.accesorios;
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListAccesorios utilerias');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListComponentes() {
    this.httpService.showComponentes().subscribe(
      data=> {
        if (data.data.hasOwnProperty('componentes')) {
          this.listComponentes = data.data.componentes;
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListComponentes utilerias');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListVidrios() {
    this.httpService.showVidrios().subscribe(
      data=> {
        if (data.data.hasOwnProperty('vidrios')) {
          this.listVidrios = data.data.vidrios;
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListVidrios utilerias');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListProductos() {
    this.httpService.showProductos().subscribe(
      data=> {
        if (data.data.hasOwnProperty('productos')) {
          this.listProductos = data.data.productos;
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListProdutos utilerias');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  userSaved(event) {
    if (event === true) {
      this.showListUsers();
    }
  }

  userEdited(event) {
    if (event === true) {
      this.showListUsers();
    }
  }

  userDeleted(event) {
    if (event === true) {
      this.showListUsers();
    }
  }

  accesorioSaved(event) {
    if (event === true) {
      this.showListAccesorios();
    }
  }

  accesorioEdited(event) {
    if (event === true) {
      this.showListAccesorios();
    }
  }

  accesorioDeleted(event) {
    if (event === true) {
      this.showListAccesorios();
    }
  }

  componenteSaved(event) {
    if (event === true) {
      this.showListComponentes();
    }
  }

  componenteEdited(event) {
    if (event === true) {
      this.showListComponentes();
    }
  }

  componenteDeleted(event) {
    if (event === true) {
      this.showListComponentes();
    }
  }

  productoSaved(event) {
    if (event === true) {
      this.showListProductos();
    }
  }

  productoEdited(event) {
    if (event === true) {
      this.showListProductos();
    }
  }

  productoDeleted(event) {
    if (event === true) {
      this.showListProductos();
    }
  }

  vidrioSaved(event) {
    if (event === true) {
      this.showListVidrios();
    }
  }

  vidrioDeleted(event) {
    if (event === true) {
      this.showListVidrios();
    }
  }

  vidrioEdited(event) {
    if (event === true) {
      this.showListVidrios();
    }
  }

  getUserByToken() {
    this.httpService.getUserByToken().subscribe(
      data => {
        let user = data.data.user_info[0];
        console.log('get user by token ');
        console.log(user);
        if (!user || user == '') {
          this.httpService.sendToLogin();
        }
        else {
          if (user.badmin == 1) {
            this.badmin = true;
          }

          if (user.bproductos == 1 || this.badmin == true) {
            this.bproductos = true;
            this.showListProductos();
          }

          if (user.bvidrios == 1 || this.badmin == true) {
            this.bvidrios = true;
            this.showListVidrios();
          }

          if (user.bperfiles == 1 || this.badmin == true) {
            this.bperfiles = true;
            this.showListComponentes();
          }

          if (user.baccesorios == 1 || this.badmin == true) {
            this.baccesorios = true;
            this.showListAccesorios();
          }

          if (user.busuarios == 1 || this.badmin == true) {
            this.busuarios = true;
            this.showListUsers();
          }

          if (user.badmin == 1 || this.badmin == true) {
            this.badmin = true;
          }
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByVidrio material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
