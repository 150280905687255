import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'del-cotizacion',
  templateUrl: './del-cotizacion.component.html',
  styleUrls: ['./del-cotizacion.component.scss']
})
export class DelCotizacionComponent implements OnInit {
  @Input() cotizacion_id: number;
  @Input() codigo: string;
  @Output() cotizacionDeleted: EventEmitter <boolean> = new EventEmitter <boolean>();


  closeResult: string;
  constructor(private modalService: NgbModal, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {}

  ngOnInit() {
    
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  saveUser() {
    this.modalService.dismissAll();
  }

  delCotizacion() {
    this.httpService.delCotizacion(this.cotizacion_id).subscribe(
      data => {
        this.cotizacionDeleted.emit(true);
        this.notification.success('Vidrios Tigre', 'Cotización Eliminada');
        setTimeout(()=>{
          this.modalService.dismissAll();
        }, 3000);
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error delCotizacion cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
