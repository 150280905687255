import {Injectable} from "@angular/core";
import {Http, Response, Headers, RequestOptions} from "@angular/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Injectable()
export class HttpService {
    constructor(private http:Http, private router: Router) { }

    getTipoDeMedida() {
        let arrayMedida = [
            {id:1, value:'ft2' },
            {id:2, value:'ft(s)' },
            {id:3, value:'hoja(s)' },
            {id:4, value:'pieza(s)' },
            {id:5, value:'tramo(s)' },
            {id:6, value:'pulgada(s)' },
            {id:7, value:'no aplica' },
        ];

        return arrayMedida;
    }

    searchUnidad(unidad) {
        switch (unidad) {
          case 1 :
            return 'ft2';
            break;
          case 2 :
            return 'ft(s)';
            break;
          case 3 :
            return 'hoja(s)';
            break;
          case 4 :
            return 'pieza(s)';
            break;
          case 5 :
            return 'tramo(s)';
            break;
          case 6 :
            return 'pulgada(s)';
            break;
        }
      }

    getPasosDescuentos() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listacciones', options).pipe(map((res: Response) => res.json()));
    }

    getTipoDeUnidad() {
        let arrayUnidad = [
            { id:1, value:"pulgadas ('')" },
            { id:2, value:"No aplica" }
        ];

        return arrayUnidad;
    }

    checkLoginAccess(username, password): Observable <any> {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        let body = {
            "usuario":  username,
            "password":  password
        };

        return this.http.post(environment.pathapi + 'login', body, options).pipe(map((res: Response) => res.json()));
    }
    
    registerUser(formUser) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();
        let permisosArray : Array <number> = [];
        let permisos : any;

        formDataUser.append('nombre' , formUser.controls['nombre'].value);
        formDataUser.append('apaterno', formUser.controls['apaterno'].value);
        formDataUser.append('amaterno', formUser.controls['amaterno'].value);
        formDataUser.append('usuario', formUser.controls['usuario'].value);
        formDataUser.append('email', formUser.controls['email'].value);
        formDataUser.append('password', formUser.controls['password'].value);
        formDataUser.append('repassword', formUser.controls['repassword'].value);
        
        permisos = formUser.controls['permisos'].value;
        formDataUser.append('permisos', JSON.stringify(permisos));
        return this.http.post(environment.pathapi + 'register', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }

    editUser(formUser, user_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();
        let permisosArray : Array <number> = [];
        let permisos : any;

        formDataUser.append('nombre' , formUser.controls['nombre'].value);
        formDataUser.append('apaterno', formUser.controls['apaterno'].value);
        formDataUser.append('amaterno', formUser.controls['amaterno'].value);
        formDataUser.append('usuario', formUser.controls['usuario'].value);
        formDataUser.append('email', formUser.controls['email'].value);
        formDataUser.append('password', formUser.controls['password'].value);
        formDataUser.append('repassword', formUser.controls['repassword'].value);
        formDataUser.append('usuario_id', user_id);
        
        permisos = formUser.controls['permisos'].value;

        formDataUser.append('permisos', JSON.stringify(permisos));

        return this.http.post(environment.pathapi + 'edituser', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }

    delUser(user_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();
       
        formDataUser.append('usuario_id', user_id);

        return this.http.post(environment.pathapi + 'deleteuser', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }

    getUserById(user_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();

        
        formDataUser.append('usuario_id', user_id);
        return this.http.post(environment.pathapi + 'getuserbyid', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }

    getUserByToken() {
        let token = localStorage.getItem("token");
        let user_info = localStorage.getItem("user_info");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();
        
        //formDataUser.append('token', token);
        formDataUser.append('user_info', user_info);
        return this.http.post(environment.pathapi + 'getuserbytoken', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }
    
    showUsers() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listusers', options).pipe(map((res: Response) => res.json()));
    }

    saveAccesorios(formAccesorio, accesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataAccesorio = new FormData();

        formDataAccesorio.append('nombre' , formAccesorio.controls['nombre'].value);
        formDataAccesorio.append('codigo', formAccesorio.controls['codigo'].value);
        formDataAccesorio.append('marca', formAccesorio.controls['marca'].value);
        formDataAccesorio.append('unidad', formAccesorio.controls['unidad'].value);
        formDataAccesorio.append('medida', formAccesorio.controls['medida'].value);
        formDataAccesorio.append('accesorio_id', accesorio_id);

        return this.http.post(environment.pathapi + 'saveaccesorios', formDataAccesorio, options).pipe(map((res: Response) => res.json())); 
    }

    showAccesorios() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listaccesorios', options).pipe(map((res: Response) => res.json()));
    }

    editAccesorio(formAccesorio, accesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataAccesorio = new FormData();
        let permisosArray : Array <number> = [];
        let permisos : any;

        formDataAccesorio.append('nombre' , formAccesorio.controls['nombre'].value);
        formDataAccesorio.append('codigo', formAccesorio.controls['codigo'].value);
        formDataAccesorio.append('marca', formAccesorio.controls['marca'].value);
        formDataAccesorio.append('unidad', formAccesorio.controls['unidad'].value);
        formDataAccesorio.append('medida', formAccesorio.controls['medida'].value);
        formDataAccesorio.append('accesorio_id', accesorio_id);

        return this.http.post(environment.pathapi + 'editaccesorio', formDataAccesorio, options).pipe(map((res: Response) => res.json())); 
    }

    getAccesorioById(accesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();

        
        formDataUser.append('accesorio_id', accesorio_id);
        return this.http.post(environment.pathapi + 'getaccesoriobyid', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }

    delAccesorio(accesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataAccesorio = new FormData();
       
        formDataAccesorio.append('accesorio_id', accesorio_id);
        return this.http.post(environment.pathapi + 'deleteaccesorio', formDataAccesorio, options).pipe(map((res: Response) => res.json())); 
    }

    saveComponentes(formComponente, bvidrio, componente_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        formDataComponente.append('nombre' , formComponente.controls['nombre'].value);
        formDataComponente.append('codigo', formComponente.controls['codigo'].value);
        formDataComponente.append('unidad', formComponente.controls['unidad'].value);
        formDataComponente.append('componente_id', componente_id);
        //formDataComponente.append('precios', JSON.stringify(precios));
        //formDataComponente.append('descuentos', JSON.stringify(descuentos));
        formDataComponente.append('bvidrio', bvidrio);

        //console.log(JSON.stringify(descuentos));
               
        return this.http.post(environment.pathapi + 'savecomponentes', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    saveCompPrecios(formPrecio, componente_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataPrecio = new FormData();

        formDataPrecio.append('precio' , formPrecio.controls['precio'].value);
        formDataPrecio.append('ancho', formPrecio.controls['ancho'].value);
        formDataPrecio.append('alto', formPrecio.controls['alto'].value);
        formDataPrecio.append('color', formPrecio.controls['color'].value);
        formDataPrecio.append('cantidad', formPrecio.controls['cantidad'].value);
        formDataPrecio.append('medida', formPrecio.controls['medida'].value);
        formDataPrecio.append('componente_id', componente_id);
               
        return this.http.post(environment.pathapi + 'saveprecios', formDataPrecio, options).pipe(map((res: Response) => res.json()));
    }

    saveAccPrecios(formPrecio, accesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataPrecio = new FormData();

        formDataPrecio.append('precio' , formPrecio.controls['precio'].value);
        formDataPrecio.append('color', formPrecio.controls['color'].value);
        formDataPrecio.append('accesorio_id', accesorio_id);
               
        return this.http.post(environment.pathapi + 'savepreciosaccesorios', formDataPrecio, options).pipe(map((res: Response) => res.json()));
    }

    showVidrios() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'getvidrios', options).pipe(map((res: Response) => res.json()));
    }

    showComponentes() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listcomponentes', options).pipe(map((res: Response) => res.json()));
    }
    
    
    editComponente(formComponente, componente_id, precios, bvidrio) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        formDataComponente.append('nombre' , formComponente.controls['nombre'].value);
        formDataComponente.append('codigo', formComponente.controls['codigo'].value);
        formDataComponente.append('unidad', formComponente.controls['unidad'].value);
        formDataComponente.append('componente_id', componente_id);
        formDataComponente.append('bvidrio', bvidrio);
        formDataComponente.append('precios', JSON.stringify(precios));


        return this.http.post(environment.pathapi + 'editcomponente', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    getComponenteById(componente_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        
        formDataComponente.append('componente_id', componente_id);
        return this.http.post(environment.pathapi + 'getcomponentebyid', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    getComponentesPrecios(componente_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        
        formDataComponente.append('componente_id', componente_id);
        return this.http.post(environment.pathapi + 'getcomponentesprecios', formDataComponente, options).pipe(map((res: Response) => res.json()));
    }

    delComponente(componente_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();
       
        formDataComponente.append('componente_id', componente_id);
        return this.http.post(environment.pathapi + 'deletecomponente', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    deleteColorPrecio(comprecio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();
       
        formDataComponente.append('comprecio_id', comprecio_id);
        return this.http.post(environment.pathapi + 'deletecolorprecio', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    deleteColorPrecioAccesorio(accprecio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();
       
        formDataComponente.append('accprecio_id', accprecio_id);
        return this.http.post(environment.pathapi + 'deletecolorprecioaccesorio', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    getColorAccesorios(accesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();
       
        formDataComponente.append('accesorio_id', accesorio_id);
        return this.http.post(environment.pathapi + 'getcoloraccesorios', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    saveProductos(formProducto) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataUser = new FormData();

        formDataUser.append('nombre' , formProducto.controls['nombre'].value);
        formDataUser.append('codigo', formProducto.controls['codigo'].value);
        formDataUser.append('unidad', formProducto.controls['unidad'].value);
        formDataUser.append('vidrios', formProducto.controls['vidrios'].value);
        formDataUser.append('ganancia', formProducto.controls['ganancia'].value);
        formDataUser.append('colores', JSON.stringify(formProducto.controls['colores'].value));
                
        return this.http.post(environment.pathapi + 'saveproductos', formDataUser, options).pipe(map((res: Response) => res.json())); 
    }

    saveComponentesEnProducto(componenteId, cantidad, productoId, perfiles_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        formDataComponente.append('componenteId', componenteId);
        formDataComponente.append('cantidad', cantidad);
        formDataComponente.append('productoId', productoId);
        formDataComponente.append('perfiles_id', perfiles_id);

        return this.http.post(environment.pathapi + 'saveproductocomponente', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    saveAccesorioEnProducto(accesorioId, cantidad, productoId, prodaccesorio_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        formDataComponente.append('accesorioId', accesorioId);
        formDataComponente.append('cantidad', cantidad);
        formDataComponente.append('productoId', productoId);
        formDataComponente.append('prodaccesorio_id', prodaccesorio_id);
        
        return this.http.post(environment.pathapi + 'saveproductoaccesorio', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    saveDescuentosEnProducto(productocompId, formDescuento) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formData = new FormData();


        formData.append('accion' , formDescuento.controls['accion'].value);
        formData.append('unidad', formDescuento.controls['medida'].value);
        formData.append('cantidad', formDescuento.controls['accioncant'].value);
        formData.append('productocompId', productocompId);
                
        return this.http.post(environment.pathapi + 'savedescuentoproducto', formData, options).pipe(map((res: Response) => res.json())); 
    }

    saveDescuentosEnVidrios(producto_id, formDescuento, descuento_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formData = new FormData();


        formData.append('accion' , formDescuento.controls['accion'].value);
        formData.append('unidad', formDescuento.controls['medida'].value);
        formData.append('cantidad', formDescuento.controls['accioncant'].value);
        formData.append('producto_id', producto_id);
        formData.append('descuento_id', descuento_id);
                
        return this.http.post(environment.pathapi + 'savedescuentovidrio', formData, options).pipe(map((res: Response) => res.json())); 
    }

    editProductos(formProducto, componentes, accesorios, producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataProducto = new FormData();

        formDataProducto.append('nombre' , formProducto.controls['nombre'].value);
        formDataProducto.append('codigo', formProducto.controls['codigo'].value);
        formDataProducto.append('unidad', formProducto.controls['unidad'].value);
        formDataProducto.append('ganancia', formProducto.controls['ganancia'].value);
        formDataProducto.append('colores', JSON.stringify(formProducto.controls['colores'].value));
        formDataProducto.append('vidrios', formProducto.controls['vidrios'].value);
        formDataProducto.append('producto_id', producto_id);
        
        return this.http.post(environment.pathapi + 'editproductos', formDataProducto, options).pipe(map((res: Response) => res.json())); 
    }

    showProductos() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listproductos', options).pipe(map((res: Response) => res.json()));
    }

    delProducto(producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataproducto = new FormData();
       
        formDataproducto.append('producto_id', producto_id);
        return this.http.post(environment.pathapi + 'deleteproducto', formDataproducto, options).pipe(map((res: Response) => res.json())); 
    }

    getProductoById(producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataproducto = new FormData();

        
        formDataproducto.append('producto_id', producto_id);
        return this.http.post(environment.pathapi + 'getproductobyid', formDataproducto, options).pipe(map((res: Response) => res.json())); 
    }

    getdescuentosbyproducto(prodcompId) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataproducto = new FormData();

        formDataproducto.append('prodcompId', prodcompId);
        return this.http.post(environment.pathapi + 'getdescuentosbyproducto', formDataproducto, options).pipe(map((res: Response) => res.json())); 
    }

    getDescuentosVidrioByProducto(producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataproducto = new FormData();

        
        formDataproducto.append('producto_id', producto_id);
        return this.http.post(environment.pathapi + 'getdescuentosvidriobyproducto', formDataproducto, options).pipe(map((res: Response) => res.json())); 
    }

    deleteComponenteFromProduct(pc_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataProductoComp = new FormData();
       
        formDataProductoComp.append('pc_id', pc_id);
        return this.http.post(environment.pathapi + 'deletecomponentefromproduct', formDataProductoComp, options).pipe(map((res: Response) => res.json())); 
    }

    deleteAccesorioFromProduct(pa_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataProductoaccesorio = new FormData();
       
        formDataProductoaccesorio.append('pa_id', pa_id);
        return this.http.post(environment.pathapi + 'deleteaccesoriofromproduct', formDataProductoaccesorio, options).pipe(map((res: Response) => res.json())); 
    }

    deleteDescuentoFromProduct(id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataDescuento = new FormData();
       
        formDataDescuento.append('id', id);
        return this.http.post(environment.pathapi + 'deletedescuentofromproduct', formDataDescuento, options).pipe(map((res: Response) => res.json())); 
    }

    delDescuentoFromVidrio(id){
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataDescuento = new FormData();
       
        formDataDescuento.append('id', id);
        return this.http.post(environment.pathapi + 'deldescuentofromvidrio', formDataDescuento, options).pipe(map((res: Response) => res.json())); 
    }

    getColores() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listcolores', options).pipe(map((res: Response) => res.json()));
    }

    getCodeCotizacion() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'getcodecotizacion', options).pipe(map((res: Response) => res.json()));
    }

    //saveCotizacion(formCotizacion, codigo, total, subtotal, iva, dateNow, cotizacion_id, costoInicial) { 
    saveCotizacion(cotizacionData, cotizacionProducto) { 
    
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataCotizacion = new FormData();

        console.log(cotizacionData);
        
        formDataCotizacion.append('cotizacion_id', cotizacionData[8].cotizacion_id);
        formDataCotizacion.append('codigo', cotizacionData[4].codeCotizacion);
        formDataCotizacion.append('fecha', cotizacionData[10].dateCotizacion);
        formDataCotizacion.append('cliente' , cotizacionData[0].cliente);
        formDataCotizacion.append('direccion' , cotizacionData[1].direccion);
        formDataCotizacion.append('telefono' , cotizacionData[2].telefono);
        formDataCotizacion.append('email' , cotizacionData[3].email);
        formDataCotizacion.append('total' , cotizacionData[5].total);
        formDataCotizacion.append('subtotal' , cotizacionData[6].subtotal);
        formDataCotizacion.append('iva', cotizacionData[7].iva);
        formDataCotizacion.append('costoInicial', cotizacionData[9].costoInicial);
        formDataCotizacion.append('productos', JSON.stringify(cotizacionProducto));
        
        return this.http.post(environment.pathapi + 'savecotizacion', formDataCotizacion, options).pipe(map((res: Response) => res.json())); 
    }

    editCotizacion(formCotizacion, codigo, total, subtotal, iva, dateNow, cotizacion_id, costoInicial) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataCotizacion = new FormData();

        formDataCotizacion.append('cotizacion_id', cotizacion_id);
        formDataCotizacion.append('codigo', codigo);
        formDataCotizacion.append('fecha', dateNow);
        formDataCotizacion.append('cliente' , formCotizacion.controls['cliente'].value);
        formDataCotizacion.append('direccion' , formCotizacion.controls['direccion'].value);
        formDataCotizacion.append('telefono' , formCotizacion.controls['telefono'].value);
        formDataCotizacion.append('email' , formCotizacion.controls['email'].value);
        formDataCotizacion.append('total' , total);
        formDataCotizacion.append('subtotal' , subtotal);
        formDataCotizacion.append('iva', iva);
        formDataCotizacion.append('productos', JSON.stringify(formCotizacion.controls['itemArrays'].value));
        
        return this.http.post(environment.pathapi + 'editcotizacion', formDataCotizacion, options).pipe(map((res: Response) => res.json())); 
    }

    showCotizaciones() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(environment.pathapi + 'listcotizaciones', options).pipe(map((res: Response) => res.json()));
    }

    delCotizacion(cotizacion_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataCotizacion = new FormData();
       
        formDataCotizacion.append('cotizacion_id', cotizacion_id);
        return this.http.post(environment.pathapi + 'deletecotizacion', formDataCotizacion, options).pipe(map((res: Response) => res.json())); 
    }

    deleteProductoCotizacion(arrayProductos, cotizacion_id, subtotal) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let cotprod_id = arrayProductos.cotproducto_id;
        let iva = subtotal * 0.08;
        let total = subtotal + iva;

        let formDataCotizacion = new FormData();
       
        formDataCotizacion.append('cotprod_id', cotprod_id);
        formDataCotizacion.append('total', total);
        formDataCotizacion.append('iva', iva.toString());
        formDataCotizacion.append('subtotal', subtotal);
        formDataCotizacion.append('cotizacion_id', cotizacion_id);

        return this.http.post(environment.pathapi + 'deleteproductocotizacion', formDataCotizacion, options).pipe(map((res: Response) => res.json())); 
    }

    getPricesByColor(item_array) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataCotizacion = new FormData();
       
        formDataCotizacion.append('producto_id', item_array.productos);
        formDataCotizacion.append('color_id', item_array.color);
        formDataCotizacion.append('vidrio_id', item_array.vidrios);
        formDataCotizacion.append('colorvidrio_id', item_array.colorvidrios);
        formDataCotizacion.append('ancho', item_array.ancho);
        formDataCotizacion.append('alto', item_array.alto);


        return this.http.post(environment.pathapi + 'getpricesbycolor', formDataCotizacion, options).pipe(map((res: Response) => res.json())); 
    }

    getCotizacionById(cotizacion_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataCotizacion = new FormData();
       
        formDataCotizacion.append('cotizacion_id', cotizacion_id);
        
        return this.http.post(environment.pathapi + 'getcotizacionbyid', formDataCotizacion, options).pipe(map((res: Response) => res.json())); 
    }

    getMaterialDeProductos(producto_id, color_id, colorvidrio_id, componente_id, ancho, alto, bdescuentos) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataMaterial = new FormData();
       
        formDataMaterial.append('producto_id', producto_id);
        formDataMaterial.append('color_id', color_id);
        formDataMaterial.append('colorvidrio_id', colorvidrio_id);
        formDataMaterial.append('componente_id', componente_id);
        formDataMaterial.append('ancho', ancho);
        formDataMaterial.append('alto', alto);
        formDataMaterial.append('bdescuentos', bdescuentos);
        
        return this.http.post(environment.pathapi + 'getmaterialdeproductos', formDataMaterial, options).pipe(map((res: Response) => res.json())); 
    }

    getColoresDeProductos(producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataMaterial = new FormData();
       
        formDataMaterial.append('producto_id', producto_id);
        
        return this.http.post(environment.pathapi + 'getcoloresbyproducto', formDataMaterial, options).pipe(map((res: Response) => res.json())); 
    }

    getVidriosByProducto(producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataMaterial = new FormData();
       
        formDataMaterial.append('producto_id', producto_id);
        
        return this.http.post(environment.pathapi + 'getvidriosbyproducto', formDataMaterial, options).pipe(map((res: Response) => res.json())); 
    }

    getVidrios() {
        let token = localStorage.getItem("token");
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
       
        return this.http.get(environment.pathapi + 'getvidrios', options).pipe(map((res: Response) => res.json()));
    }
    
    getColorVidrios(componente_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();
       
        formDataComponente.append('componente_id', componente_id);
        
        return this.http.post(environment.pathapi + 'getcolorvidrios', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    getDescuentosDeComponentes(componente) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();

        formDataComponente.append('prodcomp_id', componente.prodcomp_id);
        formDataComponente.append('componente_id', componente.comp_id);
        
        return this.http.post(environment.pathapi + 'getdescuentosdecomponentes', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    getDescuentosDeVidrios(producto_id) {
        let token = localStorage.getItem("token");
        let headers = new Headers({'Authorization': 'Bearer ' + token});
        let options = new RequestOptions({ headers: headers });
        let formDataComponente = new FormData();
       
        formDataComponente.append('producto_id', producto_id);
        
        return this.http.post(environment.pathapi + 'getdescuentosdevidrios', formDataComponente, options).pipe(map((res: Response) => res.json())); 
    }

    sendToLogin() {
        localStorage.removeItem("token");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("nombre");
        this.router.navigate(["/login"]);
    }
}