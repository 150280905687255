import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { HttpService } from '../../../services/http/http_service.service';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notifications/noti_service.service';

@Component({
  selector: 'del-user',
  templateUrl: './del-user.component.html',
  styleUrls: ['./del-user.component.scss']
})
export class DelUserComponent implements OnInit {
  @Input() user_id: number;
  @Input() user_name: string;
  @Output() userDeleted: EventEmitter <boolean> = new EventEmitter <boolean>();

  closeResult: string;
  modalOption: NgbModalOptions = {};
  options: any; 

  constructor(private modalService: NgbModal, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {}

  ngOnInit() {
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  deleteUser() {
    this.httpService.delUser(this.user_id).subscribe(
      data => {
        this.userDeleted.emit(true);
        this.notification.success('Vidrios Tigre', 'Usuario Eliminado');
        setTimeout(()=>{
          this.modalService.dismissAll();
        }, 3000);
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error deleteUser accesorio');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
