import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../services/notifications/noti_service.service';
import { HttpService } from '../services/http/http_service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss','./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  constructor(private httpService: HttpService,private notification: NotificationsService, private noti: NotiService) { }

  bcotizar = false;
  bmateriales = false;
  butilerias = false;
  bdescuentos = false;

  ngOnInit() {
    this.getUserByToken();
  }

  getUserByToken() {
    this.httpService.getUserByToken().subscribe(
      data => {
        let user = data.data.user_info[0];
        if (user.bcotizar == 1) {
          this.bcotizar = true;
        }

        if (user.bmateriales == 1) {
          this.bmateriales =  true;
        }

        if (user.butilerias == 1) {
          this.butilerias = true;
        }

        if (user.bdescuentos == 1) {
          this.bdescuentos = true;
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getUserByToken dashboard');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
