import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { CustomValidation } from '../custom-validation.validator';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'new-producto',
  templateUrl: './new-producto.component.html',
  styleUrls: ['./new-producto.component.scss']
})
export class NewProductoComponent implements OnInit {
  @Output() productoSaved: EventEmitter <boolean> = new EventEmitter <boolean>();

  formProducto: FormGroup;
  formComponente: FormGroup;
  formAccesorios: FormGroup;
  formDescuento: FormGroup;

  dropconfig = {};
  dropconfigcomp = {};
  dropconfigcolor = {};
  dropoptions = [];
  dropoptionsacc = [];
  dropoptionscolor = [];

  listComponentes = [];
  listAccesorios = [];
  listVidrios = [];

  arrayComponentes = [];
  arrayAccesorios = [];
  arraycolores : any;

  addCompStatus = true;
  addAccStatus = true;
  showError = false;
  errorprecio = false;
  errorunidad = false;

  arraymedida = [];
  arraydescuento = [];

  activeTab = '1';
  butDisabled = true;
  productoId = 0;
  colorselected: string;
  
  modalOption: NgbModalOptions = {};
  closeResult: string;
  options: any; 

  accionselected = '';
  addDescStatus = false;
  unidadselected = '';
  arraydescuentos= [];
  perfiles_id = 0;
  accesorios = [];
  arraColoresSel =[];
  producto_nombre = '';

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService ) {
    this.formProducto = this.fb.group({
      nombre: new FormControl('', Validators.required),
      codigo: new FormControl(''),
      unidad: new FormControl(''),
      colores: new FormControl(''),
      vidrios: new FormControl(''),
      ganancia: new FormControl('')
    })//, { validators : CustomValidation });

    this.formComponente = this.fb.group({
      componentes: new FormControl('', Validators.required),
      cantidadcom: new FormControl('', Validators.required),
    });

    this.formAccesorios = this.fb.group({
      cantidadacc: new FormControl('', Validators.required),
      accesorios: new FormControl('', Validators.required),
    });

    this.formDescuento = this.fb.group({
      accion: new FormControl('', Validators.required),
      medida: new FormControl('', Validators.required),
      accioncant: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.showColores();
  }

  poblarInicio() {
    
    this.arraymedida = this.httpService.getTipoDeMedida();
    this.getPasosDescuentos();

    this.showListComponentes();
    this.showListAccesorios();
    
    this.dropconfig = {
      displayKey:"description",
      search:true, 
      height: 'auto', 
      placeholder:'Seleccionar Accesorios', 
      customComparator: ()=>{}, 
      limitTo: this.dropoptions.length, 
      moreText: 'more', 
      noResultsFound: 'No se encontraron los accesorios!', 
      searchPlaceholder:'Buscar'
    }

    this.dropconfigcomp = {
      displayKey:"description",
      search:true, 
      height: 'auto', 
      placeholder:'Seleccionar Componentes', 
      customComparator: ()=>{}, 
      limitTo: this.dropoptions.length, 
      moreText: 'more', 
      noResultsFound: 'No se encontraron los componentes!', 
      searchPlaceholder:'Buscar'
    }

    this.dropconfigcolor = {
      displayKey:"description",
      search:true, 
      height: 'auto', 
      placeholder:'Seleccionar Colores', 
      customComparator: ()=>{}, 
      limitTo: this.dropoptions.length, 
      moreText: 'more', 
      noResultsFound: 'No se encontraron los colores!', 
      searchPlaceholder:'Buscar'
    }
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.poblarInicio();
    this.clearFormData();
    
    this.options = this.noti.getOptions();
  }

  showColores() {
    this.httpService.getColores().subscribe(
      data => {
        this.arraycolores = data.data.colores;
        for (let item in this.arraycolores) {
          this.dropoptionscolor.push({id: this.arraycolores[item].id, description:this.arraycolores[item].nombre});
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColores productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  onSubmit() {
    this.saveProductoForm();
  }

  onSubmitComponente() {
    this.saveComponenteForm();
  }

  onSubmitAccesorio() {
    this.saveAccesorioForm();
  }

  onSubmitVidrio() {
    this.saveVidrioForm();
  }
 
  saveProductoForm() {
    this.httpService.saveProductos(this.formProducto).subscribe(
      data => {
        if(data.message === 404) {
        } else {
          this.productoSaved.emit(true);
          this.butDisabled = false;
          this.productoId = data.data.productoId;
          this.producto_nombre = this.formProducto.controls['nombre'].value;
          this.notification.success('Vidrios Tigre', 'Producto Almacenado');
        }
      },
      error=> {
        /*console.log('error save producto');
        if (error.hasOwnProperty('_body')) {
          let errorProd = JSON.parse(error._body);
          console.log(JSON.parse(error._body));
          if (errorProd.success == false) {
            this.showError = true;
          }
        }
        this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');*/
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveProductoForm productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    )
  }

  saveComponenteForm() {
    let componenteId = this.formComponente.controls['componentes'].value['id'] ;
    let cantidad = this.formComponente.controls['cantidadcom'].value;

    this.httpService.saveComponentesEnProducto(componenteId, cantidad, this.productoId, 0).subscribe(
      data => {
        this.addComp(data.data.productocompId);
        this.clearFormDataPerfiles()
        this.notification.success('Vidrios Tigre', 'Perfil Asignado a Producto');
      }, 
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveComponenteForm productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  saveAccesorioForm() {
    let accesorioId = this.formAccesorios.controls['accesorios'].value['id'];
    let cantidad = this.formAccesorios.controls['cantidadacc'].value;
    
    this.httpService.saveAccesorioEnProducto(accesorioId, cantidad, this.productoId, 0).subscribe(
      data => {
        this.addAcc(data.data.productoaccId);
        this.clearFormDataAccesorio();
        this.notification.success('Vidrios Tigre', 'Accesorio Asignado a Producto');
      }, 
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveAccesoriosForm productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  saveVidrioForm() {
    this.httpService.saveDescuentosEnVidrios(this.productoId, this.formDescuento, 0).subscribe(
      data =>{
        this.addDescuentos(data.data.productodescId);
        this.clearFormDataPerfiles();
        this.notification.success('Vidrios Tigre', 'Descuento Almacenado');
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveVidrioForm productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  validatePrecio(event) {
    this.errorunidad = false;
    this.errorprecio = false;

    if (event.target.value > 0 ) {
      if (this.formProducto.controls['unidad'].value === '' || this.formProducto.controls['unidad'].value === null || this.formProducto.controls['unidad'].value == 0) {
          this.errorunidad = true;
      } 
    } else {
      if (this.formProducto.controls['unidad'].value > 0) {
        this.errorprecio = true;
      }
    }
  }

  validateUnidad(event) {
    this.errorunidad = false;
    this.errorprecio = false;

    if (event.target.value > 0) {
      if (this.formProducto.controls['precio'].value === '' || this.formProducto.controls['precio'].value === null || this.formProducto.controls['precio'].value == 0) {
          this.errorprecio = true;
      }
    } else {
      if (this.formProducto.controls['precio'].value > 0) {
        this.errorunidad = true;
      }
    }
  }

  clearFormData() {
    this.formProducto.controls['nombre'].setValue('');
    this.formProducto.controls['codigo'].setValue('');
    this.formProducto.controls['unidad'].setValue(0);
    this.formProducto.controls['colores'].setValue(0);
    this.formProducto.controls['vidrios'].setValue('');
    this.clearFormDataDescuento();
    this.clearFormDataAccesorio();
    this.clearFormDataPerfiles();
    this.dropoptions = [];
    this.productoId = 0;
    this.producto_nombre = '';
  }

  clearFormDataDescuento() {
    this.formDescuento.controls['accion'].setValue(0);
    this.formDescuento.controls['accioncant'].setValue('');
    this.formDescuento.controls['medida'].setValue(0);
    this.arraydescuentos = [];
  }

  clearFormDataAccesorio() {
    this.formAccesorios.controls['accesorios'].setValue(0);
    this.formAccesorios.controls['cantidadacc'].setValue('');
  }

  clearFormDataPerfiles() {
    this.formComponente.controls['componentes'].setValue([]);
    this.formComponente.controls['cantidadcom'].setValue('');
  }

  getProductoById() {
    this.httpService.getProductoById(this.productoId).subscribe(
      data => {
        if (data.data.producto) {
          let componentes = data.data.componentes;
          let accesorios = data.data.accesorios;
          let colores = data.data.colores;

          this.arraColoresSel = [];
          this.arrayComponentes = [];
          this.arrayAccesorios = [];

          for (let item in colores) {
            this.arraColoresSel.push({id: colores[item].color_id, description: colores[item].color});
          }

          for (let item in componentes) {
            this.arrayComponentes.push({id: componentes[item].pc_id, componente_id: componentes[item].id, nombre: componentes[item].nombre, cantidad: componentes[item].cantidad });
          }

          for (let item in accesorios) {
            this.arrayAccesorios.push({id: accesorios[item].pa_id, accesorio_id:accesorios[item].id, nombre: accesorios[item].nombre, cantidad: accesorios[item].cantidad});
          }
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getProductoById producto');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }


  showListAccesorios() {
    this.httpService.showAccesorios().subscribe(
      data=> {
        if (data.data.hasOwnProperty('accesorios')) {
          this.listAccesorios = data.data.accesorios;

          for (let item in this.listAccesorios) {
            this.dropoptionsacc.push({id: this.listAccesorios[item].id, description:this.listAccesorios[item].nombre});
          }
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListAccesorios productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListComponentes() {
    this.httpService.showComponentes().subscribe(
      data=> {
        if (data.data.hasOwnProperty('componentes')) {
          this.listComponentes = data.data.componentes;

          for (let item in this.listComponentes) {
            this.dropoptions.push({id: this.listComponentes[item].id, description:this.listComponentes[item].nombre });
          }
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListComponentes productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListVidrios() {

  }

  selectionChangedComp(event) {
    if (this.formComponente.controls['cantidadcom'].value > 0) {
      this.addCompStatus = false;
    }
  }

  selectionChangedAcc(event) {

    if (event.hasOwnProperty('value')) {
      if (event.value.hasOwnProperty('id')) {
        this.addAccStatus = false;
      }
    }
  }

  selectionChangedColor(event) {
    if (event.hasOwnProperty('value')) {
      if (event.value.hasOwnProperty('id')) {
        this.addAccStatus = false;
      }
    }
  }

  changeCant(event) {
    if (this.formComponente.controls['componentes'].value['id'] !== '' || this.formComponente.controls['componentes'].value['id'] !== null) {
      this.addCompStatus = false;
    }
  }

  

  changeCantAcc(event) {
    
  }

  changecolor(event) {
    this.colorselected = event.target.options[event.target.options.selectedIndex].text;
  }

  delComp(componente) {
    this.httpService.deleteComponenteFromProduct(componente['id']).subscribe(
      data => {
        const index: number = this.arrayComponentes.indexOf(componente);
        if (index !== -1) {
            this.arrayComponentes.splice(index, 1);
            this.notification.success('Vidrios Tigre', 'Perfil Eliminado de Producto');
        }   
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error delComp productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  delAcc(accesorio) {
    this.httpService.deleteAccesorioFromProduct(accesorio['id']).subscribe(
      data => {
        const index: number = this.arrayAccesorios.indexOf(accesorio);
        if (index !== -1) {
            this.arrayAccesorios.splice(index, 1);
        }   
        this.notification.success('Vidrios Tigre', 'Accesorio Eliminado de Producto');
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error delAcc productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  addComp(productocompId) {
    this.arrayComponentes.push({id:productocompId, componente_id: this.formComponente.controls['componentes'].value['id'], nombre: this.formComponente.controls['componentes'].value['description'], cantidad: this.formComponente.controls['cantidadcom'].value });
    this.formComponente.controls['cantidadcom'].setValue('');
    this.formComponente.controls['componentes'].setValue(0);

  }

  addAcc(productoaccId) {
    this.arrayAccesorios.push({id:productoaccId, accesorio_id: this.formAccesorios.controls['accesorios'].value['id'], nombre: this.formAccesorios.controls['accesorios'].value['description'], cantidad: this.formAccesorios.controls['cantidadacc'].value });
    this.formAccesorios.controls['cantidadacc'].setValue('');
    this.formAccesorios.controls['accesorios'].setValue(0);
  }

  nameChange(event) {
    if (this.showError === true) {
      this.showError = false;
    }
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === '1') {
    } else {
    }
  }

  getPasosDescuentos() {
    this.arraydescuento = [];

    this.httpService.getPasosDescuentos().subscribe(
      data => {
        this.arraydescuento = data.data.acciones;
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getPasosDescuentos productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }
  
  changedescuento(event) {
    this.accionselected = event.target.options[event.target.options.selectedIndex].text;
    let cantidad = parseFloat(this.formDescuento.controls['accioncant'].value);

    this.addDescStatus = true;

    if (event.target.value > 0) {
      if (!Number.isNaN(cantidad)) {
        this.addDescStatus = false;
      }
    }
  }

  changemedida(event) {
    this.unidadselected = event.target.options[event.target.options.selectedIndex].text;
  }

  changecantidad(event) {

  }

  addDescuentos(descuentos) {
    this.clearFormDataDescuento();

    for(let index = 0; index < descuentos.length; index ++) {
      let unidad = this.httpService.searchUnidad(descuentos[index]['prodcomp_unidad']);
      this.arraydescuentos.push({id: descuentos[index]['pa_id'], descuento_id: descuentos[index]['ac_id'], descuento: descuentos[index]['accion'], cantidad: descuentos[index]['prodcomp_cantidad'], unidad: unidad, unidad_id:descuentos[index]['prodcomp_unidad'], accion_id:descuentos[index]['ac_id'] });
    } 
  }

  deldesc(descuento) {
    this.httpService.delDescuentoFromVidrio(descuento['id']).subscribe(
      data => {
        const index: number = this.arraydescuentos.indexOf(descuento);
        this.notification.success('Vidrios Tigre', 'Descuento Eliminado');
        if (index !== -1) {
            this.arraydescuentos.splice(index, 1);
        }   
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error deldesc productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getDescuentosByProducto() {
    this.httpService.getDescuentosVidrioByProducto(this.productoId).subscribe(
      data => {
        let descuentos = data.data.producto_descuentos;
        this.addDescuentos(descuentos);
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByProducto productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  descuentoEditado(event) {
    console.log('descuento editado');
    this.getDescuentosByProducto();
  }

  perfilEditado(event) {
    this.getProductoById();
  }

  accesorioEditado(event) {
    this.getProductoById();
  }

}
