import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../services/http/http_service.service';
import { Router } from '@angular/router';

import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../services/notifications/noti_service.service';

@Component({
  selector: 'material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit {
  formMaterial: FormGroup;
  dropconfig = {};
  dropoptions = [];
  dropoptionscolor = [];
  dropoptionsvidrios = [];
  listProductos = [];
  arraycolores = [];
  arraycoloresvidrios = [];
  listVidrios = [];
  producto_elegido: string;
  producto_id : number;
  color_id: number;
  componente_id = 0;
  colorvidrio_id = 0;
  color_vidrio: string;
  alto = 0;
  ancho = 0;

  constructor(private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { 
    this.formMaterial = this.fb.group({
      productos: new FormControl('', Validators.required),
      alto: new FormControl('', Validators.required),
      ancho: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
      vidrios: new FormControl('', Validators.required),
      colvidrio: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.dropconfig = {
      displayKey: "description",
      search:true, 
      height: 'auto', 
      placeholder:'Seleccionar Producto', 
      customComparator: ()=>{}, 
      limitTo: this.dropoptions.length, 
      moreText: 'more', 
      noResultsFound: 'No se encontraron los productos!', 
      searchPlaceholder:'Buscar'
    }

    this.showListProductos();
    this.showListVidrios();
    this.clearFormData();
  }

  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
  }

  showListProductos() {
    this.httpService.showProductos().subscribe(
      data=> {
        if (data.data.hasOwnProperty('productos')) {
          this.listProductos = data.data.productos;
          for (let item in this.listProductos) {
            this.dropoptions.push({id: this.listProductos[item].id, description:this.listProductos[item].nombre});
          }
        }
        this.dropoptions.push({id:0, description: 'Otros'});
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListProductos material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  selectionChangedComp(event) {
    this.producto_id = event.value.id;
  }

  changeProducto(event) {
    this.producto_elegido = event.target.options[event.target.options.selectedIndex].text;
    this.producto_id = event.target.value;
    this.showColores();
  }

  changeColorProducto(event) {
    this.color_vidrio = event.target.options[event.target.options.selectedIndex].text;
    this.color_id = event.target.value;
  }

  changeVidrio(event, item) {
    this.componente_id = event.target.options[event.target.options.selectedIndex].value;
    this.showColoresVidrios();
  }

  changecolorVidrio(event) {
    this.colorvidrio_id = event.target.value;
  }

  changeAlto(event) {
    this.alto = event.target.value;
  }

  changeAncho(event) {
    this.ancho = event.target.value;
  }
  
  onSubmit() {

  }

  showColoresVidrios() {
    this.httpService.getColorVidrios(this.componente_id).subscribe(
      data => {
        this.arraycoloresvidrios = data.data.colores;
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColoresVidrios material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showColores() {
    this.dropoptionscolor = [];
    this.httpService.getColoresDeProductos(this.producto_id).subscribe(
      data => {
        this.arraycolores = data.data.colores;
        for (let item in this.arraycolores) {
          this.dropoptionscolor.push({id: this.arraycolores[item].id, description:this.arraycolores[item].nombre});
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColores material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListVidrios() {
    
    this.httpService.getVidrios().subscribe(
      data => {
        if (data.data.hasOwnProperty('vidrios')) {
          this.listVidrios = data.data.vidrios;
          for (let item in this.listVidrios) {
            this.dropoptionsvidrios.push({ id: this.listVidrios[item].id, description: this.listVidrios[item].nombre });
          }
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListVidrios material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  clearFormData() {
    this.formMaterial.controls['productos'].setValue(0);
    this.formMaterial.controls['ancho'].setValue('');
    this.formMaterial.controls['alto'].setValue('');
    this.formMaterial.controls['color'].setValue(0);
    this.formMaterial.controls['vidrios'].setValue(0);
    this.formMaterial.controls['colvidrio'].setValue(0);
  }

}
