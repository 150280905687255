
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) {}

    canActivate() {
        return this.checkIfLoggedIn();
    }

    private checkIfLoggedIn() {
        let loggedIn: any = localStorage.getItem("isLoggedIn");
        if (!loggedIn) {
            console.log('Not authorized');
            this.router.navigate(["login"]);
            loggedIn = false;
        } else {
            loggedIn = true;
        }

        return loggedIn;
    }

}