import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  formLogin: FormGroup;
  public loading = false;
  showError = false;
  options: any;

  constructor(private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {}

  ngOnInit() {
    this.options = this.noti.getOptions();
    this.formLogin = this.fb.group({
      usuario: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  destroyed(event) {
  
  }

  onSubmit() {
    let username = this.formLogin.controls['usuario'].value;
    let password = this.formLogin.controls['password'].value;

    this.loading =true;

    this.httpService.checkLoginAccess(username, password).subscribe(
      data => {
        this.notification.success('Vidrios Tigre', 'Bienvenido');
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("nombre", data.data.nombre);
        localStorage.setItem("user_info", data.data.user_info);

        setTimeout(()=>{
          this.loading = false;
          this.router.navigate(['/']);
        }, 3000);
      },
      error => {
        console.log(error);
        this.loading = false;
        this.showError = true;
      }
    ) 
  }

  clear(event) {
    this.showError = false;
  }

}
