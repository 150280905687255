import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'del-componente',
  templateUrl: './del-componente.component.html',
  styleUrls: ['./del-componente.component.scss']
})
export class DelComponenteComponent implements OnInit {
  @Input() componente_id: number;
  @Input() componente_nombre: string;
  @Output() componenteDeleted: EventEmitter <boolean> = new EventEmitter <boolean>();

  closeResult: string;
  modalOption: NgbModalOptions = {};
  options: any;

  constructor(private modalService: NgbModal, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {}

  ngOnInit() {
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  deleteComponente() {
    this.httpService.delComponente(this.componente_id).subscribe(
      data => {
        this.componenteDeleted.emit(true);
        this.notification.success('Vidrios Tigre', 'Perfil Eliminado');
        setTimeout(()=>{
          this.modalService.dismissAll();
        }, 3000);
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error deleteComponente accesorio');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }


}
