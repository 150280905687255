import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../services/notifications/noti_service.service';

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.scss']
})
export class CotizadorComponent implements OnInit {
  listCotizaciones = [];

  constructor(private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit() {
    this.showListCotizaciones();
  }

  cotizacionSaved(event) {
    if (event === true) {
      this.showListCotizaciones();
    }
  }

  cotizacionDeleted(event) {
    if (event === true) {
      this.showListCotizaciones();
    }
  }

  cotizacionEdited(event) {
    if (event === true) {
      this.showListCotizaciones();
    }
  }

  showListCotizaciones() {
    this.httpService.showCotizaciones().subscribe(
      data=> {
        if (data.data.hasOwnProperty('cotizaciones')) {
          this.listCotizaciones = data.data.cotizaciones;
        }
      },
      error=>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListCotizaciones cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
