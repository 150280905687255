import { AbstractControl } from '@angular/forms';
export class CustomValidation {
  static MatchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let repassword = control.get('repassword').value;
    if (password != repassword) {
      control.get('repassword').setErrors({ CustomValidation: true });
    }
    else {
      return null;
    }
  }
}