import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';

import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'print-material',
  templateUrl: './print-material.component.html',
  styleUrls: ['./print-material.component.scss']
})
export class PrintMaterialComponent implements OnInit {
  @Input() producto_elegido: string;
  @Input() producto_id: number;
  @Input() color_id: number;
  @Input() color_vidrio: string;
  @Input() colorvidrio_id: number;
  @Input() componente_id: number;
  @Input() disabled_button: boolean;
  @Input() alto: number;
  @Input() ancho: number;

  formAcc: FormGroup;
  modalOption: NgbModalOptions = {};
  closeResult: string;
  options: any;
  logoSrc = 'assets/images/logovt.jpg';
  componentes = [];
  accesorios = [];
  vidrios = [];
  vidrio_selected = [];
  productos = [];
  unidad = "pulgadas";
  cantidad_vidrios = 0;
  alto_descuento = 0;
  ancho_descuento = 0;
  material_print = [];
  producto = [];
  precio_unitario = 0;
  bmostrarcosto = false;
  compid_anterior = 0;
  array_materiales = [];

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit() {
    
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
    this.vidrio_selected = [];
    this.getUserByToken();
    this.showMaterialByProducto();
    this.compid_anterior = 0;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.precio_unitario = 0;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.precio_unitario = 0;
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  showMaterialByProducto() {
    this.precio_unitario = 0;

    this.httpService.getMaterialDeProductos(this.producto_id, this.color_id, this.colorvidrio_id, this.componente_id, this.ancho, this.alto, true).subscribe(
      data => {
        this.componentes = data.data.array_componentes;
        this.accesorios = data.data.productos_accesorios;
        this.vidrios = data.data.array_vidrios;
        this.productos = data.data.productos;
        this.precio_unitario = data.data.precio_unitario;
      
        this.setAccesorios();
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showMaterialByProducto material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  setAccesorios() {
    let accesorio_costo = 0;

    for (let item in this.accesorios) {
      let unidad_acc = this.httpService.searchUnidad(parseInt(this.accesorios[item].unidad));
      this.accesorios[item].unidad = unidad_acc;
      accesorio_costo = this.accesorios[item].cantidad * this.accesorios[item].precio
      this.accesorios[item].costo = accesorio_costo;

      this.precio_unitario = this.precio_unitario + accesorio_costo;
      this.precio_unitario = parseFloat(this.precio_unitario.toFixed(2));
    }
  }



  getUserByToken() {
    this.httpService.getUserByToken().subscribe(
      data => {
        let user = data.data.user_info[0];
        console.log(user);
        if (user.bmostrarcosto == 1 || user.badmin == 1) {
          this.bmostrarcosto = true;
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getUserByToken print-material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }
  
}
