import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'edit-prodaccesorio',
  templateUrl: './edit-prodaccesorio.component.html',
  styleUrls: ['./edit-prodaccesorio.component.scss']
})
export class EditProdaccesorioComponent implements OnInit {
  @Input() producto_id: number;
  @Input() accesorio: any;
 
  @Output() accesorioEditado: EventEmitter <boolean> = new EventEmitter <boolean>();

  closeResult: string;
  modalOption: NgbModalOptions = {};
  options: any; 
  formDescuento: FormGroup;
  arraydescuento = [];
  arraymedida = [];
  productoperfil_id = 0;
  formAccesorios: FormGroup;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService, private activeModal: NgbActiveModal) {
    this.formAccesorios = this.fb.group({
      cantidadacc: new FormControl('', Validators.required),
    });
   }

  ngOnInit() {
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
    this.clearFormDataAccesorio()

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormDataAccesorio();
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormDataAccesorio();
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  clearFormDataAccesorio() {
    this.formAccesorios.controls['cantidadacc'].setValue('');
  }

  onSubmitAccesorio() {
    this.saveAccesorioForm();
  } 

  saveAccesorioForm() {
    let accesorioId = this.accesorio['accesorio_id'];
    let cantidad = this.formAccesorios.controls['cantidadacc'].value;
    let prodaccesorio_id = this.accesorio['id'];
  

    this.httpService.saveAccesorioEnProducto(accesorioId, cantidad, this.producto_id, prodaccesorio_id).subscribe(
      data => {
        this.clearFormDataAccesorio();
        this.notification.success('Vidrios Tigre', 'Accesorio Asignado a Producto');
        this.accesorioEditado.emit(true);
        this.activeModal.close();
      }, 
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveAccesorioForm producto');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
