import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';

import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'print-descuento',
  templateUrl: './print-descuento.component.html',
  styleUrls: ['./print-descuento.component.scss']
})
export class PrintDescuentoComponent implements OnInit {

  @Input() producto_elegido: string;
  @Input() producto_id: number;
  @Input() color_producto: string;
  @Input() color_id: number;
  @Input() color_vidrio: string;
  @Input() colorvidrio_id: number;
  @Input() componente_id: number;
  @Input() componente: string;
  @Input() disabled_button: boolean;
  @Input() alto: number;
  @Input() ancho: number;

  formAcc: FormGroup;
  modalOption: NgbModalOptions = {};
  closeResult: string;
  options: any;
  logoSrc = 'assets/images/logovt.jpg';
  componentes = [];
  accesorios = [];
  vidrios = [];
  productos = [];
  vidrio_selected = [];
  unidad = "pulgadas";
  cantidad_vidrios = 0;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit() {
    console.log('Disabled button '+ this.disabled_button);
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
    this.vidrio_selected = [];
    this.showMaterialByProducto();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
      
    } else {
      return  `with: ${reason}`;
      
    }
  }

  showMaterialByProducto() {
    this.httpService.getMaterialDeProductos(this.producto_id, this.color_id, this.colorvidrio_id, this.componente_id, this.ancho, this.alto, true).subscribe(
      data => {
        this.componentes = data.data.productos_componentes;
        this.accesorios = data.data.productos_accesorios;
        this.vidrios = data.data.productos_vidrios;
        this.productos = data.data.productos;

        console.log(this.componentes);

        this.unidad = this.httpService.searchUnidad(parseInt(this.productos['unidad']));
        this.cantidad_vidrios = this.productos['vidrios'];

        this.setComponentes();        
        this.setVidrios();

        for (let item in this.accesorios) {
          let unidad_acc = this.httpService.searchUnidad(parseInt(this.accesorios[item].unidad));
          this.accesorios[item].unidad = unidad_acc;
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showMaterialByProducto descuento');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  setComponentes() {
    for (let item in this.componentes) {
      console.log(this.componentes[item]);
      let unidad_componente = this.httpService.searchUnidad(parseInt(this.componentes[item].unidad));
      console.log('unidad componente '+ unidad_componente);
      this.getDescuentosByComponente(this.componentes[item]);
      this.componentes[item].unidad = unidad_componente;
    }
  }

  setVidrios() {
    let alto_prod = this.alto;
    let ancho_prod = this.ancho;
    this.vidrio_selected.push({componente: this.componente, ancho: ancho_prod, alto: alto_prod, unidad: this.unidad, color:this.color_vidrio, cantidad: this.cantidad_vidrios});
    this.getDescuentosByVidrio(this.vidrio_selected[0]);
  }

  getDescuentosByComponente(componente) {
    let prodcomp_id = componente.prodcomp_id;
    let descuentos;

    this.httpService.getDescuentosDeComponentes(componente).subscribe(
      data => {
        descuentos = data.data.productos_descuentos;
        console.log('Descuentos');
        console.log(descuentos);
        this.setDescuentoComponente(componente, descuentos);
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByComponente descuento');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getDescuentosByVidrio(vidrio) {
    let descuentos;
    this.httpService.getDescuentosDeVidrios(this.producto_id).subscribe(
      data =>{
        descuentos = data.data.productos_descuentos;
        this.setDescuentoVidrio(vidrio, descuentos);
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByVidrio descuento');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );

    
  }

  setDescuentoComponente(componente, descuentos) {
    let ancho_descuento = this.ancho;
    let alto_descuento = this.alto;
    let descancho_cero = false;
    let descalto_cero = false;

    for (let item in descuentos) {
      console.log(descuentos[item].accion_id);
      console.log(descuentos[item].cantidad);

      if (descuentos[item].unidad == 6 || descuentos[item].unidad == 7) {
        if (descuentos[item].accion_id == 1) {
          if (descuentos[item].cantidad > 0) {
            ancho_descuento = ancho_descuento - descuentos[item].cantidad;
          } else {
            descancho_cero = true;
          }
        } 
        
        if (descuentos[item].accion_id == 2 ) {
          if (descuentos[item].cantidad > 0) {
            alto_descuento = alto_descuento - descuentos[item].cantidad;
          } else {
            descalto_cero = true;
          }
        } 

        if (descuentos[item].accion_id == 3) {
          if (descuentos[item].cantidad > 0) {
            ancho_descuento = parseFloat(ancho_descuento.toString()) + parseFloat(descuentos[item].cantidad);
          } else {
            descancho_cero = true;
          }
        } 
        
        if (descuentos[item].accion_id == 4) {
          if (descuentos[item].cantidad > 0) {
            alto_descuento = parseFloat(alto_descuento.toString()) + parseFloat(descuentos[item].cantidad);
          } else {
            descalto_cero =  true;
          }
        } 

        if (descuentos[item].accion_id == 5) {
          if (descuentos[item].cantidad > 0) {
            ancho_descuento = ancho_descuento / descuentos[item].cantidad;
          } else {
            descancho_cero = true;
          }
        } 
        
        if (descuentos[item].accion_id == 6) {
          if (descuentos[item].cantidad > 0) {
            alto_descuento = alto_descuento / descuentos[item].cantidad;
          } else {
            descalto_cero = true;
          }
        } 

      }
    }

    if (ancho_descuento != this.ancho && alto_descuento != this.alto) {
      componente.alto = 'Ancho: ' + parseFloat(ancho_descuento.toString()).toFixed(3) + ' X Alto: ' + parseFloat(alto_descuento.toString()).toFixed(3);
    } else {
      if (ancho_descuento != this.ancho) {
        componente.alto = 'Ancho: ' + parseFloat(ancho_descuento.toString()).toFixed(3)
      } else {
        if (alto_descuento != this.alto) {
          componente.alto = 'Alto: ' + parseFloat(alto_descuento.toString()).toFixed(3)
        } else {
          if (descancho_cero == true) {
            componente.alto = 'Ancho: ' + parseFloat(ancho_descuento.toString()).toFixed(3)
          } else {
            if (descalto_cero == true) {
              componente.alto = 'Alto: ' + parseFloat(alto_descuento.toString()).toFixed(3)
            }
          }
        }
      }
    }

    componente.presentacion = componente.alto;
  }

  setDescuentoVidrio(vidrios, descuentos) {
    let ancho_descuento = this.ancho;
    let alto_descuento = this.alto;

    for (let item in descuentos) {
      if (descuentos[item].unidad == 6 || descuentos[item].unidad == 7 ) {
        if (descuentos[item].accion_id == 1) {
            ancho_descuento = ancho_descuento - descuentos[item].cantidad;
        }
        
        if (descuentos[item].accion_id == 2) {
            alto_descuento = alto_descuento - descuentos[item].cantidad;
        }

        if (descuentos[item].accion_id == 3) {
            ancho_descuento = parseFloat(ancho_descuento.toString()) + parseFloat(descuentos[item].cantidad);
        }
        
        if (descuentos[item].accion_id == 4) {
          alto_descuento = parseFloat(alto_descuento.toString()) + parseFloat(descuentos[item].cantidad);
        }

        if (descuentos[item].accion_id == 5) {
          ancho_descuento = ancho_descuento / descuentos[item].cantidad;
        }
        
        if (descuentos[item].accion_id == 6) {
          alto_descuento = alto_descuento / descuentos[item].cantidad;
        }
      }
    }

    if (ancho_descuento > 0 && alto_descuento > 0) {
      vidrios.alto = 'Ancho: ' + parseFloat(ancho_descuento.toString()).toFixed(3) + ' X Alto: ' + parseFloat(alto_descuento.toString()).toFixed(3);
    } else {
      if (ancho_descuento > 0) {
        vidrios.alto = 'Ancho: ' + parseFloat(ancho_descuento.toString()).toFixed(3);
      } else {
        if (alto_descuento > 0) {
          vidrios.alto = 'Alto: ' + parseFloat(alto_descuento.toString()).toFixed(3);
        }
      }
    }
    
  }




}
