import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';
import { DecimalPipe, DatePipe } from '@angular/common';


@Component({
  selector: 'print-cotizacion',
  templateUrl: './print-cotizacion.component.html',
  styleUrls: ['./print-cotizacion.component.scss']
})
export class PrintCotizacionComponent implements OnInit {

  modalOption: NgbModalOptions = {};
  closeResult: string;
  options: any;
  logoSrc = 'assets/images/logovt.jpg';
  @Input() bDisabled: boolean;
  @Input() cotizacion_id = 0;
  cotizacion: any;
  cotizacionData = [];
  productos: any = [];
  vidrios: any = [];
  subtotal: any;
  iva: any;
  total: any;
  dateNow: any;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService, private datePipe: DatePipe) { }

  ngOnInit() {
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    console.log(this.cotizacion_id);

    this.setCotizacionData();

    this.options = this.noti.getOptions();
  }

  setCotizacionData() {
    this.cotizacionData = [];
    this.httpService.getCotizacionById(this.cotizacion_id).subscribe(
      data =>{
        this.cotizacion = data.data.cotizacion;
        this.productos = data.data.cotizacion_productos;
        this.vidrios = data.data.cotizacion_vidrios;

        this.dateNow =  this.datePipe.transform(this.cotizacion.fecha,"dd-MM-yyyy");

        this.total = parseFloat(this.cotizacion.total).toFixed(2);
        this.subtotal = parseFloat(this.cotizacion.subtotal).toFixed(2);
        this.iva = parseFloat(this.cotizacion.iva).toFixed(2);
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCotizacionData cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  mes() {

  }

}
