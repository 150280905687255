import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'edit-vidrio',
  templateUrl: './edit-vidrio.component.html',
  styleUrls: ['./edit-vidrio.component.scss']
})
export class EditVidrioComponent implements OnInit {

  @Input() vidrio_id: number;
  @Input() vidrio_name: string;
  @Output() vidrioEdited: EventEmitter <boolean> = new EventEmitter <boolean>();

  formComp: FormGroup;
  formPrecios: FormGroup;

  label_error = false; 
  errorancho = false;
  erroralto = false;
  errorvalores = false;
  addDescStatus = true;
  addPrecStatus = true;
  modalOption: NgbModalOptions = {};
  closeResult: string;
  arraymedida = [];
  arraydescuento = [];
  arrayunidad = [];
  arraycolores : any;
  arraydescuentosel = [];
  arraypreciossel = [];

  colorPrecioselected = [];
  accionselected: string;
  colorselected: string;
  cantidadselected: number;
  bmostrarmedidas = false;
  bmostraralto = false;
  bsalvarperfil = false;
  bmostrarcolorprecio = false;
  bmostrarpieza = false;
  options: any;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {
    this.formComp = this.fb.group({
      nombre: new FormControl('', Validators.required),
      codigo: new FormControl(''),
      unidad: new FormControl('', [ Validators.required ])
    }, );

    this.formPrecios = this.fb.group({
      precio: new FormControl('', Validators.required),
      ancho: new FormControl(''),
      alto: new FormControl(''),
      color: new FormControl('', Validators.required),
      cantidad: new FormControl(''),
      medida: new FormControl('')
    });
  }

  ngOnInit() {
    
  }

  showColores() {
    this.httpService.getColores().subscribe(
      data => {
        this.arraycolores = data.data.colores;
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColores vidrios');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.arraymedida = this.httpService.getTipoDeMedida();
    this.arraypreciossel = [];

    this.showColores();
    this.getComponenteById();
    this.getComponentesPrecios();

    //this.clearFormData();
    this.options = this.noti.getOptions();

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  changecantidad(event) {
    this.cantidadselected = parseFloat(event.target.value);
    let accion = this.formComp.controls['accion'].value;
    this.addDescStatus = true;

    if (!Number.isNaN(this.cantidadselected)) {
      if( accion > 0) {
        this.addDescStatus = false;
      }
    }
  }
 
  changedescuento(event) {
    this.accionselected = event.target.options[event.target.options.selectedIndex].text;
    let cantidad = parseFloat(this.formComp.controls['cantidad'].value);

    this.addDescStatus = true;

    if (event.target.value > 0) {
      if (!Number.isNaN(cantidad)) {
        this.addDescStatus = false;
      }
    }
  }

  changecolor(event) {
    this.colorselected = event.target.options[event.target.options.selectedIndex].text;
  }

  changeunidad(event) {
    this.bmostrarmedidas = false;
    this.bmostrarcolorprecio = true;
    this.arraypreciossel = [];
    this.bmostrarpieza = false;

    if (event.target.value == 0) {
      this.bmostrarcolorprecio = false;
    }

    if (event.target.value == 1 || event.target.value == 3 || event.target.value == 6) {
      this.bmostrarmedidas = true;
      this.bmostraralto = true;
    }

    if (event.target.value == 2) {
      this.bmostraralto = true;
    }

    if (event.target.value == 4 || event.target.value == 5) {
      this.bmostrarpieza = true;
    }
  }

  onSubmit() {
    this.editComponenteForm();
  }

  onSubmitPrecio() {
    this.savePrecioForm();
  }

  editComponenteForm() {
    this.httpService.editComponente(this.formComp, this.vidrio_id, this.arraypreciossel, true).subscribe(
      data => {
        if(data.message === 404) {
          this.label_error = true;
        } else {
          this.vidrioEdited.emit(true);
          this.notification.success('Vidrios Tigre', 'Vidrio Almacenado');
        }
      },
      error=> {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error editcomponenteForm vidrios');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  savePrecioForm() {
    this.httpService.saveCompPrecios(this.formPrecios, this.vidrio_id).subscribe(
      data=>{
        this.arraypreciossel.push({comprecio_id:data.data.comprecio_id, color: this.colorselected, color_id: this.formPrecios.controls['color'].value, ancho: this.formPrecios.controls['ancho'].value, alto: this.formPrecios.controls['alto'].value, precio: this.formPrecios.controls['precio'].value});
        this.formPrecios.controls['precio'].setValue('');
        this.formPrecios.controls['ancho'].setValue('');
        this.formPrecios.controls['alto'].setValue('');
        this.formPrecios.controls['color'].setValue(0);
        this.formPrecios.controls['cantidad'].setValue('');
        this.accionselected = '';
        this.addDescStatus = true;
        this.notification.success('Vidrios Tigre', 'Precio de Vidrio Almacenado');
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error savePreciosForm vidrios');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getComponenteById() {
    console.log(this.vidrio_id);
    this.httpService.getComponenteById(this.vidrio_id).subscribe(
      data => {
        console.log(data.data);
        if (data.data.componente != null || data.data.componente != '') {
          this.fillFormData(data.data.componente);
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getComponenteById vidrios');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getComponentesPrecios() {
    this.httpService.getComponentesPrecios(this.vidrio_id).subscribe(
      data => {
        this.bmostrarcolorprecio = true;
        let componentes = data.data.comprecios;
        let comprecios = [];
        this.arraypreciossel = [];
        for(let index = 0; index < componentes.length; index++) {
          comprecios = componentes[index];
          this.arraypreciossel.push({comprecio_id: comprecios['pc_id'], unidad_id: comprecios['unidad'], color: comprecios['color'], color_id: comprecios['color_id'], ancho: comprecios['ancho'], alto: comprecios['alto'], precio: comprecios['precio']});
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getComponentesPrecios vidrios');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  fillFormData(componente) {
    this.bmostrarmedidas = false;
    this.bmostraralto = false;
    this.bmostrarpieza = false;

    this.formComp.controls['nombre'].setValue(componente.nombre);
    if (componente.codigo != 'null') {
      this.formComp.controls['codigo'].setValue(componente.codigo);
    }
    
    this.formComp.controls['unidad'].setValue(componente.unidad);

    if (componente.unidad == 1 || componente.unidad == 3 || componente.unidad == 6) {
      this.bmostrarmedidas = true;
      this.bmostraralto = true;
    }

    if (componente.unidad == 2) {
      this.bmostraralto = true;
    }

    if (componente.unidad == 4 || componente.unidad == 5) {
      this.bmostrarpieza = true;
    }
    
  }

  clearFormData() {
    this.formComp.controls['nombre'].setValue('');
    this.formComp.controls['codigo'].setValue('');
    this.formComp.controls['unidad'].setValue('');

    this.formPrecios.controls['precio'].setValue('');
    this.formPrecios.controls['ancho'].setValue('');
    this.formPrecios.controls['alto'].setValue('');
    this.formPrecios.controls['color'].setValue(0);
    this.formPrecios.controls['cantidad'].setValue('');
  }

  addPrecio() {
    this.arraypreciossel.push({comprecio_id:0, unidad_id: this.formComp.controls['unidad'].value, color: this.colorselected, color_id: this.formComp.controls['color'].value, ancho: this.formComp.controls['ancho'].value, alto: this.formComp.controls['alto'].value, precio: this.formComp.controls['precio'].value});
    this.colorselected = '';
    this.formComp.controls['color'].setValue('');
    this.formComp.controls['ancho'].setValue('');
    this.formComp.controls['alto'].setValue('');
    this.formComp.controls['precio'].setValue('');
    this.addPrecStatus = true;
  }

  delPrecio(precio) {
    const index: number = this.arraypreciossel.indexOf(precio);
    let comprecio_id = 0;

    if (index !== -1) {
      comprecio_id = this.arraypreciossel[index]['comprecio_id'];
      if (comprecio_id > 0) {
        this.httpService.deleteColorPrecio(comprecio_id).subscribe(
          data => {
            this.notification.success('Vidrios Tigre', 'Precio de Vidrio Eliminado');
            this.arraypreciossel.splice(index, 1);
          },
          error => {
            if (error.status == 401) {
              this.httpService.sendToLogin();
            } else {
              console.log('error delPrecio vidrios');
              console.log(error);
              this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
            }
          }
        );
      }
    }  
  }

  onInputPrecio(event) {
    let precio = parseFloat(event.target.value);
    if (Number.isNaN(precio)) {
      this.addPrecStatus = true;
    } else {
      this.addPrecStatus = false;
    }
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'tabPerfil') {
      this.bsalvarperfil = true;
    } else {
      this.bsalvarperfil = false;
    }
  }

}
