import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../services/notifications/noti_service.service';
import { empty } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public samplePagesCollapsed = true;

  bcotizar = false;
  bmateriales = false;
  bdescuentos = false;
  butilerias = false;
  badmin = false;

  constructor(private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit() {
    this.getUserByToken();
  }

  getUserByToken() {
    this.httpService.getUserByToken().subscribe(
      data => {
        let user = data.data.user_info[0];

        if (!user || user == '') {
          this.httpService.sendToLogin();
        }
        else {

          if (user.badmin == true) {
            this.badmin = true;
          }

          if (user.bcotizar) {
            this.bcotizar = true;
          }

          if (user.bmateriales == 1) {
            this.bmateriales =  true;
          }

          if (user.bproductos == 1 || user.bvidrios == 1 || user.bperfiles == 1 || user.baccesorios == 1 || user.busuarios == 1) {
            this.butilerias = true;
          }

          if (user.bdescuentos == 1) {
            this.bdescuentos = true;
          }
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByVidrio material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
