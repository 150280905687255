import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';

import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'new-accesorio',
  templateUrl: './new-accesorio.component.html',
  styleUrls: ['./new-accesorio.component.scss']
})
export class NewAccesorioComponent implements OnInit {
  @Output() accesorioSaved: EventEmitter <boolean> = new EventEmitter <boolean>();

  formAcc: FormGroup;
  formPrecios: FormGroup;
  label_error = false; 
  modalOption: NgbModalOptions = {};
  closeResult: string;
  arraymedida = [];
  options: any;
  bmostrarprecios= false; 
  arraycolores : any;
  colorselected = 0;
  arraypreciossel = [];
  accesorio_id = 0;
  accesorio_nombre = '';

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {
    this.formAcc = this.fb.group({
      nombre: new FormControl('', Validators.required),
      codigo: new FormControl(''),
      marca: new FormControl(''),
      unidad: new FormControl('', Validators.required),
      medida: new FormControl('', Validators.required),
    });

    this.formPrecios = this.fb.group({
      precio: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.arraymedida = this.httpService.getTipoDeMedida();
    this.clearFormData();
    this.showColores();
    this.options = this.noti.getOptions();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
      
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
      
    }
  }

  onSubmit() {
    this.saveAccesorioForm();
  }

  onSubmitPrecios() {
    this.savePrecioForm();
  }
 
  saveAccesorioForm() {
    this.httpService.saveAccesorios(this.formAcc, this.accesorio_id).subscribe(
      data => {
        if(data.message === 404) {
          this.label_error = true;
        } else {
          this.bmostrarprecios = true;
          this.accesorio_id = data.data.accesorio_id;
          this.accesorio_nombre = this.formAcc.controls['nombre'].value;
          this.accesorioSaved.emit(true);
          this.notification.success('Vidrios Tigre', 'Accesorio Almacenado');
          
        }
      },
      error=> {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveAccesorioForm accesorio');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  clearFormData() {
    this.formAcc.controls['nombre'].setValue('');
    this.formAcc.controls['codigo'].setValue('');
    this.formAcc.controls['marca'].setValue('');
    this.formAcc.controls['medida'].setValue('');

    this.formPrecios.controls['precio'].setValue('');
    this.formPrecios.controls['color'].setValue(0);

    this.arraycolores = [];
    this.arraypreciossel = [];
    this.accesorio_nombre = '';
    this.bmostrarprecios = false;
  }

  showColores() {
    this.httpService.getColores().subscribe(
      data => {
        this.arraycolores = data.data.colores;
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColores componente');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  changecolor(event) {
    this.colorselected = event.target.options[event.target.options.selectedIndex].text;
  }

  savePrecioForm() {
    this.httpService.saveAccPrecios(this.formPrecios, this.accesorio_id).subscribe(
      data=>{
        this.arraypreciossel.push({accprecio_id:data.data.accprecio_id, color: this.colorselected, precio: this.formPrecios.controls['precio'].value});
        this.formPrecios.controls['precio'].setValue('');
        this.formPrecios.controls['color'].setValue(0);
        this.notification.success('Vidrios Tigre', 'Precio de Perfil Almacenado');
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error savePrecioForm componente');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  delPrecio(precio) {
    const index: number = this.arraypreciossel.indexOf(precio);
    if (index !== -1) {
      let accprecio_id = this.arraypreciossel[index]['accprecio_id'];
      if (accprecio_id > 0) {
        this.httpService.deleteColorPrecioAccesorio(accprecio_id).subscribe(
          data => {
            this.notification.success('Vidrios Tigre', 'Precio de Accesorio Eliminado');
            this.arraypreciossel.splice(index, 1);
          },
          error => {
            if (error.status == 401) {
              this.httpService.sendToLogin();
            } else {
              console.log('error delPrecio new-accesorio');
              console.log(error);
              this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
            }
          }
        );
      }
    }  
  }

  
}
