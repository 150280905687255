import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';

import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'edit-accesorio',
  templateUrl: './edit-accesorio.component.html',
  styleUrls: ['./edit-accesorio.component.scss']
})
export class EditAccesorioComponent implements OnInit {
  @Input() accesorio_id: number;
  @Input() accesorio_name: string;
  @Output() accesorioEdited: EventEmitter <boolean> = new EventEmitter <boolean>();

  formAcc: FormGroup;
  formPrecios: FormGroup;
  label_error = false; 
  modalOption: NgbModalOptions = {};
  closeResult: string;
  arraymedida = [];
  arraycolores : any = [];
  arraypreciossel = [];
  options: any;
  colorselected = '';

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {
    this.formAcc = this.fb.group({
      nombre: new FormControl('', Validators.required),
      codigo: new FormControl(''),
      marca: new FormControl(''),
      unidad: new FormControl('', Validators.required),
      medida: new FormControl('', Validators.required),
    });

    this.formPrecios = this.fb.group({
      precio: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.arraymedida = this.httpService.getTipoDeMedida();
    this.getAccesorioById();
    this.getColorAccesorios();
    this.showColores();
    this.options = this.noti.getOptions();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  onSubmit() {
    this.editAccesorioForm();
  }

  onSubmitPrecios() {
    this.savePrecioForm();
  }

  changecolor(event) {
    this.colorselected = event.target.options[event.target.options.selectedIndex].text;
  }

  editAccesorioForm() {
    this.httpService.editAccesorio(this.formAcc, this.accesorio_id).subscribe(
      data => {
        if(data.message === 404) {
          this.label_error = true;
        } else {
          if (data.message === 200) {
            this.accesorioEdited.emit(true);
            this.notification.success('Vidrios Tigre', 'Accesorio Almacenado');
            setTimeout(()=>{
              this.modalService.dismissAll();
            }, 3000);
          } 
        }
      },
      error=> {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error save accesorio');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  savePrecioForm() {
    this.httpService.saveAccPrecios(this.formPrecios, this.accesorio_id).subscribe(
      data=>{
        this.arraypreciossel.push({accprecio_id:data.data.accprecio_id, color: this.colorselected, precio: this.formPrecios.controls['precio'].value});
        this.formPrecios.controls['precio'].setValue('');
        this.formPrecios.controls['color'].setValue(0);
        this.notification.success('Vidrios Tigre', 'Precio de Perfil Almacenado');
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error savePrecioForm componente');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getAccesorioById() {
    this.httpService.getAccesorioById(this.accesorio_id).subscribe(
      data => {
        if (data.message = 200) {
          if (data.data.accesorio) {
            this.fillFormData(data.data.accesorio);
          }
        } 
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getAccesorioById accesorio');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getColorAccesorios() {
    this.httpService.getColorAccesorios(this.accesorio_id).subscribe(
      data => {
        if (data.message = 200) {
          if (data.data.colores) {
            let colores = data.data.colores;
            let accprecios = [];
            console.log(this.accesorio_id);
            console.log(colores);
            if (colores.length > 0) {
              for(let index = 0; index < colores.length; index++) {
                accprecios = colores[index];
                this.arraypreciossel.push({accprecio_id: accprecios['accprecio_id'], color: accprecios['color'], precio: accprecios['precio']});
              }
            }

          }
        } 
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getAccesorioById accesorio');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  fillFormData(accesorio) {
    this.formAcc.controls['nombre'].setValue(accesorio.nombre);
    if (accesorio.codigo != 'null') {
      this.formAcc.controls['codigo'].setValue(accesorio.codigo);
    }
    
    this.formAcc.controls['marca'].setValue(accesorio.marca);
    this.formAcc.controls['unidad'].setValue(accesorio.unidad);
    this.formAcc.controls['medida'].setValue(accesorio.medida);


  }

  clearFormData() {
    this.formAcc.controls['nombre'].setValue('');
    this.formAcc.controls['codigo'].setValue('');
    this.formAcc.controls['marca'].setValue('');
    this.formAcc.controls['medida'].setValue('');
    this.formAcc.controls['unidad'].setValue(0);

    this.formPrecios.controls['precio'].setValue('');
    this.formPrecios.controls['color'].setValue(0);

    this.arraycolores = [];
    this.arraypreciossel = [];
  }

  showColores() {
    this.httpService.getColores().subscribe(
      data => {
        this.arraycolores = data.data.colores;
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColores componente');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  delPrecio(precio) {
    const index: number = this.arraypreciossel.indexOf(precio);
    if (index !== -1) {
      let accprecio_id = this.arraypreciossel[index]['accprecio_id'];
      if (accprecio_id > 0) {
        this.httpService.deleteColorPrecioAccesorio(accprecio_id).subscribe(
          data => {
            this.notification.success('Vidrios Tigre', 'Precio de Accesorio Eliminado');
            this.arraypreciossel.splice(index, 1);
          },
          error => {
            if (error.status == 401) {
              this.httpService.sendToLogin();
            } else {
              console.log('error delPrecio new-accesorio');
              console.log(error);
              this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
            }
          }
        );
      }
    }  
  }


}
