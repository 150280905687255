import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';

import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';
import { DecimalPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'new-cotizacion',
  templateUrl: './new-cotizacion.component.html',
  styleUrls: ['./new-cotizacion.component.scss']
})

export class NewCotizacionComponent implements OnInit {
  @Output() cotizacionSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  formCotizacion: FormGroup;

  dropconfig = {};
  dropconfigvidrio = {};
  dropoptions = [];
  dropoptionsvidrios = [];
  arraymedida = [];

  closeResult: string;

  modalOption: NgbModalOptions = {};
  options: any;
  logoSrc = 'assets/images/logovt.jpg';
  listProductos = [];
  listVidrios = [];
  codeCotizacion = '';
  arrayProductos = [];
  arraycolores = [];
  arraycoloresvidrios = [];
  producto_id = 0;
  vidrio_id = 0;
  color_id = 0;
  colorvidrio_id = 0;
  componente_id = 0;
  alto = 0;
  ancho = 0;
  unidad_id = 0;
  cantidad = 0;
  subtotal: number = 0;
  total: number = 0;
  iva: number = 0;

  bshowvidrio = false;
  bshowAlmacenar = false;
  bshowPrevio = false;
  bshowCalcular = true;
  producto = [];

  cotizacionData: any = [];
  cotizacionProducto: any = [];
  cotizacion_id = 0;
  costoInicial = 0;
  bmostrarcosto = false;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService, private decimalPipe: DecimalPipe, private datePipe: DatePipe) {
    this.formCotizacion = this.fb.group({
      cliente: new FormControl('', Validators.required),
      fecha: new FormControl('', Validators.required),
      direccion: new FormControl(''),
      telefono: new FormControl(''),
      email: new FormControl(''),
      ganancia: new FormControl(''),
      itemArrays: this.fb.array([this.createItem()])
    });
  }

  toggleLogo() { }
  editLogo() { }

  ngOnInit() {
    this.dropconfig = {
      displayKey: "description",
      search: true,
      height: 'auto',
      placeholder: 'Seleccionar Producto',
      customComparator: () => { },
      limitTo: this.dropoptions.length,
      moreText: 'more',
      noResultsFound: 'No se encontraron los productos!',
      searchPlaceholder: 'Buscar'
    }

    this.dropconfigvidrio = {
      displayKey: "description",
      search: true,
      height: 'auto',
      placeholder: 'Seleccionar Vidrio',
      customComparator: () => { },
      limitTo: this.dropoptionsvidrios.length,
      moreText: 'more',
      noResultsFound: 'No se encontraron registros!',
      searchPlaceholder: 'Buscar'
    }
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.clearFormData();
    this.arraymedida = this.httpService.getTipoDeMedida();
    this.options = this.noti.getOptions();
    this.showListProductos();
    this.getCodeCotizacion();
    this.getUserByToken();

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.cotizacionSaved.emit(true);
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.cotizacionSaved.emit(true);
      return 'by clicking on a backdrop';
    } else {
      this.cotizacionSaved.emit(true);
      return `with: ${reason}`;
    }
  }

  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
  }

  createItem(): FormGroup {
    return this.fb.group({
      productos: new FormControl(''),
      cantidad: new FormControl(''),
      descripcion: new FormControl(''),
      ancho: new FormControl(''),
      alto: new FormControl(''),
      color: new FormControl(''),
      unidad: new FormControl(''),
      precio: new FormControl(''),
      total: new FormControl(''),
      vidrios: new FormControl(''),
      colorvidrios: new FormControl('')
    });
  }

  addItem() {
    (this.formCotizacion.controls['itemArrays'] as FormArray).push(this.createItem());
    let itemArray = this.formCotizacion.controls['itemArrays'].value.length;
    itemArray = itemArray -1;
    (this.formCotizacion.controls['itemArrays'] as FormArray).at(itemArray).patchValue({'cantidad': 1});
  }

  removeItem(item) {
    let itemProducto = this.formCotizacion.controls['itemArrays'].value;
    let itemArray = this.formCotizacion.controls['itemArrays'].value.length;
    
    this.subtotal = 0 - itemProducto[item].total;
    for (let i = 0; i < itemArray; i++) {
      this.subtotal = this.subtotal + parseFloat(itemProducto[i].total);
    }

    this.iva = this.subtotal * 0.08;
    this.total = this.subtotal + this.iva;
    console.log('subtotal '+this.subtotal);
        
    if (this.cotizacion_id > 0) {
      this.httpService.deleteProductoCotizacion(this.arrayProductos[item], this.cotizacion_id, this.subtotal).subscribe(
        data =>{
          (this.formCotizacion.controls['itemArrays'] as FormArray).removeAt(item);
        },
        error =>{
          console.log('error removeitem');
        }
      );
    } else {
      (this.formCotizacion.controls['itemArrays'] as FormArray).removeAt(item);
    }
  }


  clearFormData() {
    let dateNow: any;
    dateNow =  this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.dropoptions = [];
    this.dropoptionsvidrios = [];
    this.subtotal = 0;
    this.iva = 0;
    this.total = 0;
    this.cotizacionData = [];
    this.bshowCalcular =  true;

    this.formCotizacion.reset();
    this.formCotizacion.controls['cliente'].setValue('');
    this.formCotizacion.controls['fecha'].setValue(dateNow);
    this.formCotizacion.controls['direccion'].setValue('');
    this.formCotizacion.controls['telefono'].setValue('');
    this.formCotizacion.controls['email'].setValue('');
    while (this.formCotizacion.controls['itemArrays'].value.length !== 1) {
      (this.formCotizacion.controls['itemArrays'] as FormArray).removeAt(1);
    }
    (this.formCotizacion.controls['itemArrays'] as FormArray).at(0).patchValue({'cantidad': 1});

  }

  showListProductos() {
    this.httpService.showProductos().subscribe(
      data => {
        if (data.data.hasOwnProperty('productos')) {
          this.dropoptions = [];
          this.listProductos = [];
          this.listProductos = data.data.productos;
          for (let item in this.listProductos) {
            this.dropoptions.push({ id: this.listProductos[item].id, description: this.listProductos[item].nombre });
          }
        }
        this.dropoptions.push({ id: 0, description: 'Otros' });
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListProductos cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showListVidrios() {
    this.httpService.getVidrios().subscribe(
      data => {
        this.dropoptionsvidrios = [];
        this.listVidrios = [];
        if (data.data.hasOwnProperty('vidrios')) {
          this.listVidrios = data.data.vidrios;
          for (let item in this.listVidrios) {
            this.dropoptionsvidrios.push({ id: this.listVidrios[item].id, description: this.listVidrios[item].nombre });
          }
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListVidrios cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  selectionChangedComp(event) {
    this.producto_id = event.target.options[event.target.options.selectedIndex].value;
    this.showColores();
    this.showListVidrios();
  }

  selectionChangedVidrios(event) {

  }

  onSubmit() {
    if (this.formCotizacion.controls['itemArrays'].value.length > 0) {
      let itemArray = this.formCotizacion.controls['itemArrays'].value.length;
      this.saveCotizacionForm();
    }
  }

  getCodeCotizacion() {
    this.httpService.getCodeCotizacion().subscribe(
      data => {
        this.codeCotizacion = data.data.code;
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getCodeCotizacion cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  saveCotizacionForm() {
    this.setCotizacionData();

    this.httpService.saveCotizacion(this.cotizacionData, this.cotizacionProducto).subscribe(
      data => {

        if (data.message === 404) {

        } else {
          this.notification.success('Vidrios Tigre', 'Cotización Almacenada');
          this.cotizacionSaved.emit(true);
          this.cotizacion_id = data.data.cotizacion_id;
          this.arrayProductos =  data.data.productos;
          this.bshowPrevio = true;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveCotizacionForm cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showColores() {
    this.httpService.getColoresDeProductos(this.producto_id).subscribe(
      data => {
        this.arraycolores = data.data.colores;
        this.producto = data.data.producto;
        this.formCotizacion.controls['ganancia'].setValue(parseFloat(this.producto['ganancia']).toFixed(2));
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColores cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  showColoresVidrios() {
    this.httpService.getColorVidrios(this.componente_id).subscribe(
      data => {
        this.arraycoloresvidrios = data.data.colores;
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showColoresVidrios cotizacion');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  changeColor(event, item) {
    this.color_id = event.target.options[event.target.options.selectedIndex].value;
  }

  changeVidrio(event, item) {
    this.componente_id = event.target.options[event.target.options.selectedIndex].value;
    this.showColoresVidrios();
  }

  changeColorVidrio(event, item) {
    this.colorvidrio_id = event.target.options[event.target.options.selectedIndex].value;
  }

  changeAncho(event, item) {
  }

  changeAlto(event, item) {
  }

  changeCantidad(event, item) {
  }

  getSubtotal(item_object, precio_producto) {
    let total: any;
    let porcentaje = 0;
    let precio_unitario = 0;
    let ganancia = 0;

    ganancia = parseFloat(this.formCotizacion.controls['ganancia'].value);
    if (isNaN(ganancia)) {
      ganancia = 1;
    }
    console.log('Precio unitario '+ precio_producto);
    console.log('Ganancia '+ ganancia);
    porcentaje = (parseFloat(precio_producto) * (ganancia / 100));
    precio_unitario = precio_producto + porcentaje;
    (this.formCotizacion.controls['itemArrays'] as FormArray).at(item_object).patchValue({'precio': precio_unitario.toFixed(2)});
    
    total = this.formCotizacion.controls['itemArrays'].value[item_object].cantidad * precio_unitario;
    total = total.toFixed(2);

    this.subtotal = parseFloat(this.subtotal.toString()) + parseFloat(total);
    let iva = parseFloat(this.subtotal.toString()) * 0.08;
    this.iva = iva;
    this.total = parseFloat(this.subtotal.toString()) + this.iva;
    
    total = this.decimalPipe.transform(total, '1.2-2');
    (this.formCotizacion.controls['itemArrays'] as FormArray).at(item_object).patchValue({'total': total});
    console.log(this.formCotizacion.controls['itemArrays'].value[item_object].total);
  }

  setCotizacionData() {
    let itemArray = this.formCotizacion.controls['itemArrays'].value.length;
    let item = this.formCotizacion.controls['itemArrays'].value;
    let dateNow: any;
    dateNow =  this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.cotizacionData = [];
    this.cotizacionProducto = [];

    this.cotizacionData = [
      {cliente: this.formCotizacion.controls['cliente'].value},
      {direccion: this.formCotizacion.controls['direccion'].value},
      {telefono: this.formCotizacion.controls['telefono'].value},
      {email: this.formCotizacion.controls['email'].value},
      {codeCotizacion: this.codeCotizacion},
      {total: this.total},
      {subtotal: this.subtotal},
      {iva: this.iva},
      {cotizacion_id: this.cotizacion_id},
      {costoInicial: this.costoInicial},
      {dateCotizacion: dateNow}
    ];

    console.log('array productos');
    console.log(this.arrayProductos);

    for (let i = 0; i < itemArray; i++) {
      this.cotizacionProducto.push(item[i]);

      if (this.arrayProductos.length > 0) 
        if (this.arrayProductos.length >= this.cotizacionProducto.length) {
          this.cotizacionProducto[i].cotproducto_id = this.arrayProductos[i].cotproducto_id;
        } else {
          this.cotizacionProducto[i].cotproducto_id = 0;
        }
      else
        this.cotizacionProducto[i].cotproducto_id = 0;
    }
  }

  calcularCotizacion() {
    let itemArray = this.formCotizacion.controls['itemArrays'].value.length;
    let item = this.formCotizacion.controls['itemArrays'].value;
    let precio_unitario = 0;
    let bCotizar = true;

    this.bshowAlmacenar = false;
    this.bshowCalcular = false;

    this.total = 0;
    this.subtotal = 0;
    this.iva = 0;
    this.costoInicial = 0;

    for (let i = 0; i < itemArray; i++) {
      bCotizar = this.validarCotizacion(item[i]);
      if (bCotizar == false) {
        this.notification.error('Vidrios Tigre', 'Verificar los valores en los campos');
        this.bshowCalcular = true;
        break;
      }
      this.httpService.getPricesByColor(item[i]).subscribe(
        data => {
          (this.formCotizacion.controls['itemArrays'] as FormArray).at(i).patchValue({'precio': 0});
          (this.formCotizacion.controls['itemArrays'] as FormArray).at(i).patchValue({'total': 0});

          precio_unitario = data.data.precio_unitario;
          this.getSubtotal(i, precio_unitario);
          console.log('precio unitario');
          console.log(precio_unitario);
          this.bshowCalcular = true;
          this.bshowAlmacenar = true;
         
        },
        error =>{
          if (error.status == 401) {
            this.httpService.sendToLogin();
          } else {
            console.log('error calcularCotizacion cotizacion');
            console.log(error);
            this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
          }
        }
      );
    }
    
  }

  validarCotizacion(item) {
    if (item.productos <= 0 || isNaN(item.productos)) {
      return false;
    }

    if (item.color <= 0 || isNaN(item.color)) {
      return false;
    }

    if (item.ancho <= 0 || isNaN(item.ancho)) {
      return false;
    }

    if (item.alto <= 0 || isNaN(item.alto)) {
      return false;
    }
  }

  getUserByToken() {
    this.httpService.getUserByToken().subscribe(
      data => {
        let user = data.data.user_info[0];
        console.log(user);
        if (!user || user == '') {
          this.httpService.sendToLogin();
        } else {
          if (user.bmostrarcosto == 1 || user.badmin == 1) {
            this.bmostrarcosto = true;
          }
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getUserByToken print-material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
