import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'descuentos',
  templateUrl: './descuentos.component.html',
  styleUrls: ['./descuentos.component.scss']
})
export class DescuentosComponent implements OnInit {
  @Input() productocompId: number;
  @Input() butDisabled: boolean;
  @Input() perfil: string;
  @Output() DescuentoSaved: EventEmitter <boolean> = new EventEmitter <boolean>();

  accionselected: string;
  unidadselected: string;
  formDescuento: FormGroup;
  addDescStatus: boolean;
  arraydescuento = [];
  arraymedida = [];
  arraydescuentos = [];

  modalOption: NgbModalOptions = {};
  closeResult: string;
  options: any;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {
    this.formDescuento = this.fb.group({
      accion: new FormControl('', Validators.required),
      medida: new FormControl(''),
      accioncant: new FormControl(''),
    });
  }

  ngOnInit() {
    
  }

  changedescuento(event) {
    this.accionselected = event.target.options[event.target.options.selectedIndex].text;
    let cantidad = parseFloat(this.formDescuento.controls['accioncant'].value);

    this.addDescStatus = true;

    if (event.target.value > 0) {
      if (!Number.isNaN(cantidad)) {
        this.addDescStatus = false;
      }
    }
  }

  changemedida(event) {
    this.unidadselected = event.target.options[event.target.options.selectedIndex].text;
  }

  changecantidad(event) {

  }

  onSubmit() {
    this.httpService.saveDescuentosEnProducto(this.productocompId, this.formDescuento).subscribe(
      data =>{
        this.addComp(data.data.productodescId);
        this.notification.success('Vidrios Tigre', 'Descuento Almacenado');
      },
      error => {
        this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
      }
    );
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.getPasosDescuentos();
    this.arraymedida = this.httpService.getTipoDeMedida();
    this.getDescuentosByProducto();

    this.options = this.noti.getOptions();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  private clearFormData() {
    this.formDescuento.controls['accion'].setValue(0);
    this.formDescuento.controls['accioncant'].setValue('');
    this.formDescuento.controls['medida'].setValue(0);
  }

  addComp(descuentoId) {
    this.arraydescuentos.push({id: descuentoId, descuento_id: this.formDescuento.controls['accion'].value, descuento: this.accionselected, cantidad: this.formDescuento.controls['accioncant'].value, unidad: this.unidadselected  });
    this.clearFormData();
  }

  deldesc(descuento) {
    this.httpService.deleteDescuentoFromProduct(descuento['id']).subscribe(
      data => {
        const index: number = this.arraydescuentos.indexOf(descuento);
        this.notification.success('Vidrios Tigre', 'Descuento Eliminado');
        if (index !== -1) {
            this.arraydescuentos.splice(index, 1);
        }   
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error deldesc productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getDescuentosByProducto() {
    this.arraydescuentos = [];

    this.httpService.getdescuentosbyproducto(this.productocompId).subscribe(
      data => {
        let descuentos = data.data.producto_descuentos;
        
        for(let index = 0; index < descuentos.length; index ++) {
          let unidad = this.httpService.searchUnidad(descuentos[index]['prodcomp_unidad']);
          this.arraydescuentos.push({id: descuentos[index]['pa_id'], descuento_id: descuentos[index]['ac_id'], descuento: descuentos[index]['accion'], cantidad: descuentos[index]['prodcomp_cantidad'], unidad: unidad});
          
          this.clearFormData();
        } 
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByProducto descuentos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }


  getPasosDescuentos() {
    this.arraydescuento = [];

    this.httpService.getPasosDescuentos().subscribe(
      data => {
        this.arraydescuento = data.data.acciones;
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getPasosDescuentos productos');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

}
