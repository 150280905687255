import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { HttpService } from '../../../services/http/http_service.service';
import { environment } from '../../../../environments/environment';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';
import { CustomValidation } from '../custom-validation.validator';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notifications/noti_service.service';

@Component({
  selector: 'new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})

export class NewUserComponent implements OnInit {
  @Output() userSaved: EventEmitter <boolean> = new EventEmitter <boolean>();
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  formUser: FormGroup;
  label_error = false; 
  options: any;

  modalOption: NgbModalOptions = {};
  closeResult: string; 
  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private notification: NotificationsService, private httpService: HttpService, private noti: NotiService) {
    this.formUser = this.fb.group({
      nombre: new FormControl('', Validators.required),
      apaterno: new FormControl('', Validators.required),
      amaterno: new FormControl(''),
      usuario: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      repassword: new FormControl('', Validators.required),
      permisos: new FormControl('', Validators.required)
    }, { validators : CustomValidation.MatchPassword });
  }

  ngOnInit() {
    

  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.dropdownList = [
      { item_id: 1, item_text: 'Permiso de administrador' },
      { item_id: 2, item_text: 'Permiso para cotizar' },
      { item_id: 3, item_text: 'Permiso para imprimir descuentos' },
      { item_id: 4, item_text: 'Permiso para imprimir materiales' },
      { item_id: 5, item_text: 'Permiso para visualizar costo' },
      { item_id: 6, item_text: 'Permiso para utilerías productos' },
      { item_id: 7, item_text: 'Permiso para utilerías vidrios' },
      { item_id: 8, item_text: 'Permiso para utilerías perfiles' },
      { item_id: 9, item_text: 'Permiso para utilerías accesorios' },
      { item_id: 10, item_text: 'Permiso para utilerías usuarios' }
    ];
    this.selectedItems = [
      
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Elegir todos los permisos',
      unSelectAllText: 'Deseleccionar todos los permisos',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.options = this.noti.getOptions();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  onSubmit() {
    this.saveUserForm();
  }

  saveUserForm() {
    this.httpService.registerUser(this.formUser).subscribe(
      data => {
        if(data.message === 404) {
          this.label_error = true;
        } else {
          this.userSaved.emit(true);
          this.notification.success('Vidrios Tigre', 'Usuario Almacenado');
          setTimeout(()=>{
            this.modalService.dismissAll();
          }, 3000);
        }
      },
      error=> {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveUserForm usuario');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  clearFormData() {
    this.formUser.controls['nombre'].setValue('');
    this.formUser.controls['apaterno'].setValue('');
    this.formUser.controls['amaterno'].setValue('');
    this.formUser.controls['usuario'].setValue('');
    this.formUser.controls['email'].setValue('');
    this.formUser.controls['password'].setValue('');
    this.formUser.controls['repassword'].setValue('');
    this.formUser.controls['permisos'].setValue('');
    this.selectedItems = [];
  }

  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
  }
}
