import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'edit-prodperfil',
  templateUrl: './edit-prodperfil.component.html',
  styleUrls: ['./edit-prodperfil.component.scss']
})
export class EditProdperfilComponent implements OnInit {
  @Input() producto_id: number;
  @Input() perfil: any;
 
  @Output() perfilEditado: EventEmitter <boolean> = new EventEmitter <boolean>();

  closeResult: string;
  modalOption: NgbModalOptions = {};
  options: any; 
  formDescuento: FormGroup;
  arraydescuento = [];
  arraymedida = [];
  productoperfil_id = 0;
  formComponente: FormGroup;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {
    this.formComponente = this.fb.group({
      cantidadcom: new FormControl('', Validators.required),
    });
   }

  ngOnInit() {
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
    this.clearFormDataPerfiles()

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormDataPerfiles();
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormDataPerfiles();
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  clearFormDataPerfiles() {
    this.formComponente.controls['cantidadcom'].setValue('');
  }

  onSubmitComponente() {
    this.saveComponenteForm();
  }

  saveComponenteForm() {
    let componenteId = this.perfil['componente_id'] ;
    let cantidad = this.formComponente.controls['cantidadcom'].value;

    this.httpService.saveComponentesEnProducto(componenteId, cantidad, this.producto_id, this.perfil['id']).subscribe(
      data => {
        this.notification.success('Vidrios Tigre', 'Perfil Asignado a Producto');
        this.perfilEditado.emit(true);
      }, 
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveComponenteForm material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }



}
