import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notifications/noti_service.service';

@Component({
  selector: 'edit-prodvidrio',
  templateUrl: './edit-prodvidrio.component.html',
  styleUrls: ['./edit-prodvidrio.component.scss']
})
export class EditProdvidrioComponent implements OnInit {
  @Input() producto_id: number;
  @Input() descuento: any;
 
  @Output() descuentoEditado: EventEmitter <boolean> = new EventEmitter <boolean>();

  closeResult: string;
  modalOption: NgbModalOptions = {};
  options: any; 
  formDescuento: FormGroup;
  arraydescuento = [];
  arraymedida = [];
  productovidrio_id = 0;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) { 
    this.formDescuento = this.fb.group({
      accion: new FormControl('', Validators.required),
      medida: new FormControl('', Validators.required),
      accioncant: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
  }

  open(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.options = this.noti.getOptions();
    this.formDescuento.controls['medida'].setValue(0);
    this.arraymedida = this.httpService.getTipoDeMedida();
    this.getPasosDescuentos();
    this.editVidrios();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormDataDescuento();
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormDataDescuento();
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onSubmitVidrio() {
    this.saveVidrioForm();
  }

  saveVidrioForm() {
    this.httpService.saveDescuentosEnVidrios(this.producto_id, this.formDescuento, this.productovidrio_id).subscribe(
      data =>{
        console.log(data.data.descuentos);
        this.descuentoEditado.emit(true);
        this.notification.success('Vidrios Tigre', 'Descuento de vidrio editado');
        
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error saveVidrioForm producto');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  getPasosDescuentos() {
    this.arraydescuento = [];
    this.formDescuento.controls['accion'].setValue(0);

    this.httpService.getPasosDescuentos().subscribe(
      data => {
        this.arraydescuento = data.data.acciones;
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getPasosDescuentos producto');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }

  clearFormDataDescuento() {
    this.formDescuento.controls['accion'].setValue(0);
    this.formDescuento.controls['accioncant'].setValue('');
    this.formDescuento.controls['medida'].setValue(0);
    this.productovidrio_id = 0;
    this.producto_id = 0;
  }

  editVidrios() {
    this.productovidrio_id = this.descuento.id;
    this.formDescuento.controls['accion'].setValue(this.descuento.accion_id);
    this.formDescuento.controls['accioncant'].setValue(this.descuento.cantidad);
    this.formDescuento.controls['medida'].setValue(this.descuento.unidad_id);
  }

}
