import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsComponent } from './forms/forms.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { TablesComponent } from './tables/tables.component';
import { IconsComponent } from './icons/icons.component';
import { TypographyComponent } from './typography/typography.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AccordionsComponent } from './accordions/accordions.component';
import { BadgesComponent } from './badges/badges.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { CarouselComponent } from './carousel/carousel.component';
import { TabsComponent } from './tabs/tabs.component';
import { AuthGuard } from './guards/auth-guard.service';
import { SigninComponent } from './account/signin/signin.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { MaterialComponent } from './material/material.component';
import { UtileriasComponent } from './utilerias/utilerias.component';
import { SignoutComponent } from './account/signout/signout.component';
import { DescProductoComponent } from './desc-producto/desc-producto.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { ErrorLayoutComponent } from './layouts/error-layout/error-layout.component';

const routes: Routes = [
  { path: '', 
    component: SiteLayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch:'full'  },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'cotizador', component: CotizadorComponent, canActivate: [AuthGuard] },
      { path: 'descuento', component: DescProductoComponent, canActivate: [AuthGuard] },
      { path: 'material', component: MaterialComponent, canActivate: [AuthGuard] },
      { path: 'utilerias', component: UtileriasComponent, canActivate: [AuthGuard] },
      { path: 'logout', component: SignoutComponent, canActivate: [AuthGuard] },
      { path: 'forms', component: FormsComponent },
      { path: 'buttons', component: ButtonsComponent},
      { path: 'tables', component: TablesComponent },
      { path: 'icons', component: IconsComponent},
      { path: 'typography', component: TypographyComponent },
      { path: 'alerts', component: AlertsComponent },
      { path: 'accordions', component: AccordionsComponent },
      { path: 'badges', component: BadgesComponent },
      { path: 'progressbar', component: ProgressbarComponent },
      { path: 'breadcrumbs', component: BreadcrumbsComponent },
      { path: 'pagination', component: PaginationComponent },
      { path: 'dropdowns', component: DropdownComponent },
      { path: 'tooltips', component: TooltipsComponent },
      { path: 'carousel', component: CarouselComponent },
      { path: 'tabs', component: TabsComponent }
    ]
  },
  { 
    path: '', component: AuthLayoutComponent, 
    children: [
      { path: 'login', component: SigninComponent}
    ]
  },
  {
    path: '', component: ErrorLayoutComponent,
    children: [
      { path: '**', component: ErrorpageComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
