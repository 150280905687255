import {Injectable} from "@angular/core";
import {Http, Response, Headers, RequestOptions} from "@angular/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

@Injectable()
export class NotiService {
    constructor(private http:Http) { }

    getOptions() {
        let options = {
            position: ["middle", "center"],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: false,
            clickIconToClose: true
        };

        return options;
    }
}