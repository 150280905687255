import { Component, OnInit } from '@angular/core';
import { Router, Route } from '@angular/router';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.signout();
  }

  signout() {
    localStorage.removeItem("user_info");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("nombre");
    localStorage.removeItem("token");
    this.router.navigate(["/login"]);
  }

}
