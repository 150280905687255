import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../services/http/http_service.service';
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../services/notifications/noti_service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  user: any; 

  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }

  usuarioName;

  constructor(config: NgbDropdownConfig, private httpService: HttpService, private notification: NotificationsService, private noti: NotiService) {
    config.placement = 'bottom-right';
  }
  ngOnInit() {
    this.usuarioName = localStorage.getItem("nombre");
    this.getUserByToken();
  }

  getUserByToken() {
    this.httpService.getUserByToken().subscribe(
      data => {
        this.user = data.data.user_info[0];
        if (!this.user || this.user == '') {
          this.httpService.sendToLogin();
        }
        else {

         
        }
      },
      error =>{
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error getDescuentosByVidrio material');
          console.log(error);
          this.notification.error('Vidrios Tigre', 'Error: contacte al administrador');
        }
      }
    );
  }


}
