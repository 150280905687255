import { BrowserModule  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MaterialModule } from './modules/material.module';

import { DecimalPipe, DatePipe } from '@angular/common';
import { DatePickerModule } from 'ngk-date-picker';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsComponent } from './forms/forms.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { TablesComponent } from './tables/tables.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AccordionsComponent } from './accordions/accordions.component';
import { BadgesComponent } from './badges/badges.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { CarouselComponent } from './carousel/carousel.component';
import { TabsComponent } from './tabs/tabs.component';
import { AuthGuard } from './guards/auth-guard.service';
import { SigninComponent } from './account/signin/signin.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { ErrorLayoutComponent } from './layouts/error-layout/error-layout.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { MaterialComponent } from './material/material.component';
import { UtileriasComponent } from './utilerias/utilerias.component';
import { NewUserComponent } from './account/registration/new-user/new-user.component';
import { EditUserComponent } from './account/registration/edit-user/edit-user.component';
import { DelUserComponent } from './account/registration/del-user/del-user.component';
import { EditAccesorioComponent } from './accesorios/edit-accesorio/edit-accesorio.component';
import { DelAccesorioComponent } from './accesorios/del-accesorio/del-accesorio.component';
import { NewAccesorioComponent } from './accesorios/new-accesorio/new-accesorio.component';
import { NewComponenteComponent } from './componentes/new-componente/new-componente.component';
import { EditComponenteComponent } from './componentes/edit-componente/edit-componente.component';
import { DelComponenteComponent } from './componentes/del-componente/del-componente.component';
import { ProductosComponent } from './productos/productos.component';
import { NewProductoComponent } from './productos/new-producto/new-producto.component';
import { EditProductoComponent } from './productos/edit-producto/edit-producto.component';
import { DelProductoComponent } from './productos/del-producto/del-producto.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NewCotizacionComponent } from './cotizador/new-cotizacion/new-cotizacion.component';
import { EditCotizacionComponent } from './cotizador/edit-cotizacion/edit-cotizacion.component';
import { DelCotizacionComponent } from './cotizador/del-cotizacion/del-cotizacion.component';
import { HttpService } from './services/http/http_service.service';
import { SignoutComponent } from './account/signout/signout.component';

import { DescuentosComponent } from './productos/descuentos/descuentos.component';
import { NotiService } from './services/notifications/noti_service.service';
import { PrintMaterialComponent } from './material/print-material/print-material.component';
import {NgxPrintModule} from 'ngx-print';
import { NewVidrioComponent } from './vidrios/new-vidrio/new-vidrio.component';
import { EditVidrioComponent } from './vidrios/edit-vidrio/edit-vidrio.component';
import { DelVidrioComponent } from './vidrios/del-vidrio/del-vidrio.component';
import { DescProductoComponent } from './desc-producto/desc-producto.component';
import { PrintDescuentoComponent } from './desc-producto/print-descuento/print-descuento.component';
import { PrintCotizacionComponent } from './cotizador/print-cotizacion/print-cotizacion.component';
import { EditProdvidrioComponent } from './productos/edit-prodvidrio/edit-prodvidrio.component';
import { EditProdperfilComponent } from './productos/edit-prodperfil/edit-prodperfil.component';
import { EditProdaccesorioComponent } from './productos/edit-prodaccesorio/edit-prodaccesorio.component';

import { AutoLogoutComponent } from './account/autologout/autologout.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    FormsComponent,
    ButtonsComponent,
    TablesComponent,
    TypographyComponent,
    IconsComponent,
    AlertsComponent,
    AccordionsComponent,
    BadgesComponent,
    ProgressbarComponent,
    BreadcrumbsComponent,
    PaginationComponent,
    DropdownComponent,
    TooltipsComponent,
    CarouselComponent,
    TabsComponent,
    SigninComponent,
    AuthLayoutComponent,
    SiteLayoutComponent,
    ErrorpageComponent,
    ErrorLayoutComponent,
    CotizadorComponent,
    MaterialComponent,
    UtileriasComponent,
    NewUserComponent,
    EditUserComponent,
    DelUserComponent,
    EditAccesorioComponent,
    DelAccesorioComponent,
    NewAccesorioComponent,
    NewComponenteComponent,
    EditComponenteComponent,
    DelComponenteComponent,
    ProductosComponent,
    NewProductoComponent,
    EditProductoComponent,
    DelProductoComponent,
    NewCotizacionComponent,
    EditCotizacionComponent,
    DelCotizacionComponent,
    SignoutComponent,
    DescuentosComponent,
    PrintMaterialComponent,
    NewVidrioComponent,
    EditVidrioComponent,
    DelVidrioComponent,
    DescProductoComponent,
    PrintDescuentoComponent,
    PrintCotizacionComponent,
    EditProdvidrioComponent,
    EditProdperfilComponent,
    EditProdaccesorioComponent,
    AutoLogoutComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SelectDropDownModule,
    NgxLoadingModule.forRoot({}),
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    MaterialModule,
    NgxPrintModule,
    DatePickerModule
  ],
  providers: [AuthGuard, HttpService, NotiService, DecimalPipe, DatePipe, NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule { }